// @ts-nocheck
import React from 'react';
import axios from 'axios';

import API_ENDPOINTS from '../../const/ApiEndPoints';
import { isValueEmpty } from '../../utils/Utils';

export default function useEntity() {
  const [sectionData, setSectionData] = React.useState();
  const [entityList, setEntityList] = React.useState([]);
  const customEntityList = (body) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.CREATE_ENTITY}`,
        { params: body }
      )
      .then((res) => {
        if (res?.data) {
          setEntityList(isValueEmpty(res.data) ? [] : res.data);
        }
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const getModelsDetailsOfWorkflowTemplate = (body) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.GET_MODEL_DETAILS_OF_WORKFLOW_CHANGES}`,
        { params: body }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const transitionAllModels = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.TRANSITION_ALL_MODELS}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const getSingleEntity = (body) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.GET_SINGLE_ENTITY}`,
        { params: body }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  const createCustomEntity = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.CREATE_ENTITY}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const updateCustomEntity = (body) => {
    return axios
      .put(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.CREATE_ENTITY}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const getSectionsDetails = (body) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.GET_SECTIONS_DETAILS}`,
        { params: body }
      )
      .then((res) => {
        setSectionData(res.data);
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const createSection = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.CREATE_SECTION}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const deleteSection = (body) => {
    return axios
      .delete(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.REMOVE_SECTION}`,
        { params: body }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const getCustomEntityTemplate = (body) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.GET_ATTRIBUTES}`,
        { params: body }
      )
      .then((res) => {
        setSectionData(res.data);
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const addAttribute = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.CREATE_ATTRIBUTES}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const updateAttribute = (body) => {
    return axios
      .put(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.CREATE_ATTRIBUTES}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const deleteAttribute = (body) => {
    return axios
      .delete(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.REMOVE_ATTRIBUTE}`,
        { params: body }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const attachWorkflow = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.ATTACH_WORKFLOW}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  const deleteTemplate = (body) => {
    return axios
      .delete(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.DELETE_TEMPLATE}`,
        { params: body }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const reorderTemplate = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.REORDER_TEMPLATE}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  /**
   * @function getAllCustomEntityList
   * @param {*} body - type of Entity
   * @returns array of template names
   * @example getAllCustomEntityList({entityType="ModelInventory"})
   */
  const getAllCustomEntityList = (body) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.GET_ALL_CUSTOM_ENTITY}`,
        { params: body }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  const createIssueType = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.UM.GET_ISSUE_TYPE}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const updateIssueType = (body) => {
    return axios
      .put(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.UM.UPDATE_ISSUE_TYPE}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const getAllIssueTypesList = () => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.UM.GET_ISSUE_TYPE}`
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const deleteIssueType = (id) => {
    return axios
      .delete(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.UM.DELETE_ISSUE_TYPE}?id=${id}`
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const getAllSummarySections = () => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.UM.GET_SUMMARY_SECTIONS}`
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const createSummarySections = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.UM.CREATE_SUMMARY_SECTIONS}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const updateSummarySections = (body) => {
    return axios
      .put(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.UM.UPDATE_SUMMARY_SECTIONS}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const deleteSummarySections = (id) => {
    return axios
      .delete(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.UM.DELETE_SUMMARY_SECTIONS}?id=${id}`
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const getWorkflowRoles = (workflowName) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.GET_WORKFLOW_ROLES}`,
        { params: workflowName }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const checkTemplateForAffectedEntities = (params) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.CE.CHECK_TEMPLATE}`,
        {
          params,
        }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  return {
    customEntityList,
    createCustomEntity,
    getSectionsDetails,
    createSection,
    sectionData,
    setSectionData,
    deleteSection,
    getCustomEntityTemplate,
    addAttribute,
    deleteAttribute,
    attachWorkflow,
    deleteTemplate,
    updateAttribute,
    reorderTemplate,
    entityList,
    updateCustomEntity,
    getSingleEntity,
    getAllCustomEntityList,
    createIssueType,
    updateIssueType,
    getAllIssueTypesList,
    deleteIssueType,
    getAllSummarySections,
    createSummarySections,
    updateSummarySections,
    deleteSummarySections,
    getModelsDetailsOfWorkflowTemplate,
    transitionAllModels,
    getWorkflowRoles,
    checkTemplateForAffectedEntities,
  };
}
