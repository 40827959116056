// @ts-nocheck
import axios from 'axios';
import { useDispatch, useSelector } from 'react-redux';
import SwalToast from '../../components/Common/SwalTost';
import {
  loginSuccess,
  setUserPermissions,
} from '../../store/slices/UserSilcer';
import API_ENDPOINTS from '../../const/ApiEndPoints';

export default function useAuth() {
  const { users } = useSelector((state) => state);
  const dispatch = useDispatch();
  const login = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.AUTH.LOGIN_USER}`,
        body
      )
      .then((res) => {
        dispatch(
          loginSuccess({
            token: res.data.tokens.access_token,
            refresh: res.data.tokens.refresh_token,
            user: res.data.user,
            profile: res.data.profile,
            remember: body.remember_me,
          })
        );
        if (window.apm) {
          // www.elastic.co/guide/en/apm/agent/rum-js/current/agent-api.html#apm-set-user-context
          window.apm.setUserContext({
            username: res?.data?.user?.username,
            id: res?.data?.user?.id,
            email: res?.data?.user?.email,
          });
        }
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const getAllUsers = () => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.AUTH.GET_ALL_USER}`
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const getAllRoles = () => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.AUTH.GET_ALL_ROLES}`
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };

  const createUser = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.AUTH.CREATE_USER}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const updateUser = (body, id) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.AUTH.UPDATE_USER}`,
        body,
        { params: { userid: id } }
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  const suspendUser = (status, id) => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.AUTH.SUSPEND_USER}?action=${status}&user_id=${id}`
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  /**
   * @function fetchUserPermissions
   * @description to get current user permission
   * @returns permission object
   */
  const fetchUserPermissions = () =>
    axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.AUTH.USER_PERMISSION}`,
        // @ts-ignore
        { loader: false }
      )
      .then((resp) => {
        if (Object.keys(resp?.data?.UserPermission).length <= 0) {
          SwalToast({
            icon: 'info',
            title:
              'This user account is not associated with any group. Please contact admin.',
          });
        }
        dispatch(
          setUserPermissions({
            permissions: resp?.data?.UserPermission,
          })
        );
      })
      .catch((error) => {
        dispatch(
          setUserPermissions({
            permissions: {},
          })
        );
        throw error;
      });
  const forgetPassword = (body) =>
    axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.AUTH.FORGET_PASSWORD}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });

  const resetPassword = (body) =>
    axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.AUTH.RESET_PASSWORD}`,
        body
      )
      .then((res) => {
        return res;
      });

  const getUserSetting = () => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.AUTH.USER_SETTING}`
      )
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw error;
      });
  };
  const getIsLoggedInUserAdminOfAGroup = () => {
    return axios
      .get(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.AUTH.IS_LOGGED_IN_USER_ADMIN_OF_GROUP}`
      )
      .then((resp) => {
        return resp;
      })
      .catch((error) => {
        throw error;
      });
  };
  const updateUserSetting = (body) => {
    return axios
      .post(
        `${API_ENDPOINTS.MODULE_BASE_URL.AUTH}${API_ENDPOINTS.AUTH.USER_SETTING}`,
        body
      )
      .then((res) => {
        return res;
      })
      .catch((err) => {
        throw err;
      });
  };
  return {
    users,
    login,
    getAllUsers,
    getAllRoles,
    createUser,
    fetchUserPermissions,
    updateUser,
    suspendUser,
    forgetPassword,
    resetPassword,
    updateUserSetting,
    getUserSetting,
    getIsLoggedInUserAdminOfAGroup,
  };
}
