// @ts-nocheck
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography, Button, Box } from '@mui/material';
import { ReactComponent as NimbusLogo } from '../../assets/Icons/MRMVaultColored.svg';

const NotAuthorized = () => {
  const history = useNavigate();

  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ height: '70vh', flexDirection: 'column' }}
    >
      <Box p={1} marginBottom={2}>
        <NimbusLogo height="100px" width="170px" />
      </Box>
      <Typography
        variant="h5"
        color="primary"
        fontWeight="bold"
        sx={{ fontSize: 20, textAlign: 'center' }}
      >
        You are not authorized to access this
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={() => history('/dashboard')}
        sx={{ marginTop: 4 }}
      >
        Go Back Home
      </Button>
    </Grid>
  );
};

export default NotAuthorized;
