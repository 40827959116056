// @ts-nocheck
import {
  Box,
  Dialog,
  Grid,
  IconButton,
  Typography,
  TextField,
  Stack,
  Button,
  Avatar,
  Divider,
} from '@mui/material';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { styled } from '@mui/material/styles';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { useFormik } from 'formik';
import { cloneDeep, isEmpty } from 'lodash';
import { string, object } from 'yup';
import { useEffect, useState } from 'react';

import DialogTransition from '../Common/Transition/DialogTransition';
import Multiselect from '../Common/Multiselect';
import MODULE_SUB_MODULE from '../../const/moduleSubmodule';
import {
  STEP_TITLES_CREATE_MODE,
  STEP_TITLES_EDIT_MODE,
  MODULE_PERMISSIONS_OPTIONS,
  SUB_MODULE_PERMISSIONS_OPTIONS,
  VALUE_MAPPINGS,
} from '../../const/UserManagement';
import REGEX from '../../const/Regex';
import SwalToast from '../Common/SwalTost';
import { INVALID_REGEX_MESSAGE } from '../../const/CommonConst';
import { ReactComponent as DropdownArrow } from '../../assets/images/DropdownArrow.svg';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.other.white,
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.other.white,
}));

/**
@function CreateEditGroupModal
* @description provide a UI and functionality for creating and updating groups
* @param {bool} open - opens the modal.
* @param {function} handleClose - function to change the open state
* @param {function} getGroupDetails - get and set the list of  all groups 
* @param {function} createGroup - make api call to create a new group 
* @param {function} updateGroup - make api call to update a selected group 
* @param {string} mode - in what mode the modal is open either create or update 
* @param {array} userList - list of all the users
* @param {object} selectedGroup - selected group for update
*/
const CreateEditGroupModal = ({
  open = false,
  handleClose,
  getGroupDetails,
  createGroup,
  updateGroup,
  mode = 'create',
  userList,
  selectedGroup,
  templateList,
  setSkipPageReset,
}) => {
  const [step, setStep] = useState('first');
  const [moduleSubModules, setModuleSubModules] = useState(cloneDeep({}));

  const formValidationSchema = object({
    groupName: string()
      .max(150, 'max characters should be 150')
      .required('Required')
      .matches(REGEX.SPECIAL_CHARACTERS_NOT_ALLOWED, INVALID_REGEX_MESSAGE)
      .matches(REGEX.CANNOT_START_WITH_NUMBERS, INVALID_REGEX_MESSAGE),
    groupDescription: string()
      .max(254, 'max characters should be 254')
      .required('Required')
      .matches(
        REGEX.WHOLE_STRING_CANNOT_BE_SPECIAL_CHARACTERS,
        INVALID_REGEX_MESSAGE
      ),
  });

  // Function to recursively process the object and extract the permissions
  function extractPermissions(obj, parentKey = '') {
    let result = {};

    // Iterate over the object keys
    Object.keys(obj).forEach((key) => {
      // This objKey is calculated because we are using template in the backend as key for MI and MA templates
      const objKey =
        (obj[key].key === 'MItemplate' && 'template') ||
        (obj[key].key === 'MAtemplate' && 'template') ||
        obj[key].key;

      const currentKey = parentKey ? `${parentKey}_${objKey}` : objKey;
      const value = obj[key];

      // Check if the current key represents a submodule
      if (Object.hasOwn(value, 'subModules')) {
        const subModulePermissions = extractPermissions(
          value.subModules,
          currentKey
        );
        result = { ...result, ...subModulePermissions };
      }

      // Check if the current key has a permission value
      if (Object.hasOwn(value, 'permission')) {
        result[currentKey] = value.permission;
      }
    });

    return result;
  }

  const formikForm = useFormik({
    initialValues: {
      groupName: '',
      groupDescription: '',
      userList: [],
      adminList: [],
    },
    validationSchema: formValidationSchema,
    onSubmit: (values) => {
      const permissionJson = extractPermissions(moduleSubModules);

      const body = {
        ...values,
        userList: values?.userList?.map((item) => item?.value),
        adminList: values?.adminList?.map((item) => item?.value),
        permissionJson,
        groupName: values?.groupName?.trim(),
      };
      if (mode === 'create') {
        createGroup(body).then((res) => {
          if (res) {
            getGroupDetails();
            SwalToast({
              icon: 'success',
              title: res?.data?.msg,
            });
            formikForm.handleReset();
            setStep('first');
            handleClose();
            SwalToast({
              icon: 'warning',
              title:
                'Administrative settings have been changed. These changes require a refresh of the application to take effect.',
            });
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        });
      } else {
        const payload = {
          ...body,
          groupid: selectedGroup?.id,
        };

        updateGroup(payload).then((res) => {
          if (res) {
            getGroupDetails();
            SwalToast({
              icon: 'success',
              title: res?.data?.msg,
            });
            formikForm.handleReset();
            setStep('first');
            handleClose();
            // turn on the flag to not reset the page
            setSkipPageReset(true);
            SwalToast({
              icon: 'warning',
              title:
                'Administrative settings have been changed. These changes require a refresh of the application to take effect.',
            });
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
        });
      }
    },
  });

  /**
   * @function addTemplateModule
   * Adds a template module and its submodules to the moduleSubmoduleTree dynamically.
   *
   * @param {Object} moduleSubmoduleTree - The moduleSubmoduleTree object to add the template module to.
   * @returns {Object} The modified moduleSubmoduleTree object with the template module added.
   */

  function addTemplateModule(moduleSubmoduleTree) {
    const updatedModuleSubmoduleTree = cloneDeep(moduleSubmoduleTree);

    updatedModuleSubmoduleTree.MI.subModules.TMP = {
      objKey: 'TMP',
      label: 'Templates',
      key: 'MItemplate',
      subModules: {},
      permission: 'View',
    };
    updatedModuleSubmoduleTree.MA.subModules.TMP = {
      objKey: 'TMP',
      label: 'Templates',
      key: 'MAtemplate',
      subModules: {},
      permission: 'View',
    };
    templateList.forEach((template) => {
      if (template?.entity_type === 'ModelInventory') {
        updatedModuleSubmoduleTree.MI.subModules.TMP.subModules[
          `TMP_ModelInventory_${template?.entity_name}`
        ] = {
          objKey: `TMP_ModelInventory_${template?.entity_name}`,
          label: `${template?.entity_name}`,
          key: `${template?.entity_name}`,
          permission: 'View',
        };
      } else {
        updatedModuleSubmoduleTree.MA.subModules.TMP.subModules[
          `TMP_ModelAssociation_${template?.entity_name}`
        ] = {
          objKey: `TMP_ModelAssociation_${template?.entity_name}`,
          label: `${template?.entity_name}`,
          key: `${template?.entity_name}`,
          permission: 'View',
        };
      }
    });
    updatedModuleSubmoduleTree.MA.subModules.TMP.subModules.TMP_ModelAssociation_All =
      {
        objKey: `TMP_ModelAssociation_All`,
        label: `All_ModelAssociation`,
        key: `All`,
        permission: 'View',
      };
    return updatedModuleSubmoduleTree;
  }

  /**
   * @function addPermissionKey
   * Adds a permission key to an object and its subModules initially.
   *
   * @param {Object} obj - The object to add the permission key to.
   * @returns {Object} The modified object with the permission key added.
   */

  function addPermissionKey(obj) {
    Object.keys(obj).forEach((key) => {
      const property = obj[key];
      if (['DBC', 'DBN'].includes(key)) {
        property.permission = 'No';
      } else {
        property.permission = 'View';
      }

      if (Object.hasOwn(property, 'subModules')) {
        addPermissionKey(property?.subModules);
      }
    });

    return obj;
  }

  const handleCloseModal = () => {
    handleClose();
    setStep('first');
    formikForm.handleReset();
  };

  /**
   * @function calculateModulePermission
   * Calculates the module permission based on the submodule permissions.
   *
   * @param {Object} module - The module object containing subModules.
   * @returns {string} The calculated module permission.
   */

  const calculateModulePermission = (module) => {
    const moduleCopy = cloneDeep(module);

    // Extracting submodule permissions as an array
    const submodulePermissions = Object.values(moduleCopy?.subModules).map(
      (subModule) => subModule?.permission
    );

    // Helper function to check if all permissions match the target permissions
    const allPermissionsEqual = (permissions, targetPermissions) =>
      permissions.every((permission) => targetPermissions.includes(permission));

    // Using a switch statement to handle different permission scenarios
    switch (true) {
      case allPermissionsEqual(submodulePermissions, ['View', 'No']):
        return 'View'; // Return 'View' if all permissions are either 'View' or 'No'
      case allPermissionsEqual(submodulePermissions, ['Manage', 'Yes']):
        return 'Manage'; // Return 'Manage' if all permissions are either 'Manage' or 'Yes'
      case allPermissionsEqual(submodulePermissions, ['None']):
        return 'None'; // Return 'None' if all permissions are 'None'
      default:
        return 'Custom'; // Return 'Custom' for any other combination of permissions
    }
  };

  /**
   * @function updateTemplatePermission
   * Updates the permission of an object with a specific objKey to a newPermission value.
   *
   * @param {Object|Array} obj - The object to update the permission.
   * @param {string} objKey - The objKey to match for updating the permission.
   * @param {string} newPermission - The new permission value to set.
   * @returns {void}
   */

  function updateTemplatePermission(obj, objKey, newPermission) {
    if (typeof obj === 'object' && obj !== null) {
      if (Object.hasOwn(obj, 'objKey') && obj.objKey === objKey) {
        // eslint-disable-next-line no-param-reassign
        obj.permission = newPermission;
      }
      Object.keys(obj).forEach((key) => {
        updateTemplatePermission(obj[key], objKey, newPermission);
      });
    } else if (Array.isArray(obj)) {
      obj.forEach((item) => {
        updateTemplatePermission(item, objKey, newPermission);
      });
    }
  }

  /**
   * @function handlePermissionChange
   * Handles the change of permissions for a module or submodule.
   *
   * @param {string} moduleKey - The key of the module.
   * @param {string} subModuleKey - The key of the submodule. Pass null or undefined if changing the module permission.
   * @param {string} option - The new permission option to apply.
   * @param {boolean} [isTemplate=false] - Indicates if the permission change is for a template.
   * @returns {void}
   */

  const handlePermissionChange = (
    moduleKey,
    subModuleKey,
    option,
    isTemplate = false
  ) => {
    const updatedModuleSubModules = cloneDeep(moduleSubModules);

    const module = updatedModuleSubModules[moduleKey];
    const subModule = subModuleKey ? module?.subModules?.[subModuleKey] : null;

    if (isTemplate) {
      // If we select template to change permission of it then updating the templates permission.
      updateTemplatePermission(updatedModuleSubModules, subModuleKey, option);
      module.subModules.TMP.permission = calculateModulePermission(
        module?.subModules?.TMP
      );

      // Updating module permission based on templates permissions.
      module.permission = calculateModulePermission(module);
      setModuleSubModules(cloneDeep(updatedModuleSubModules));
      return;
    }

    switch (true) {
      case Boolean(subModule):
        if (subModuleKey === 'TMP') {
          // If we click on templates parent module, i.e. Templates (objKey : 'TMP') then change all the templates permissions to the permission of module.
          Object.values(subModule.subModules).forEach((template) => {
            // eslint-disable-next-line no-param-reassign
            template.permission = option;
          });
        }

        subModule.permission = option;
        // Updating module permission based on subModules permissions.
        module.permission = calculateModulePermission(module);
        break;

      case Boolean(module):
        module.permission = option;
        // Updating Submodules permission when we change module permission

        Object.values(module.subModules).forEach((subModuleItem) => {
          if (['DBC', 'DBN'].includes(subModuleItem?.objKey)) {
            // eslint-disable-next-line no-param-reassign
            subModuleItem.permission = VALUE_MAPPINGS?.[option];
          } else {
            // eslint-disable-next-line no-param-reassign
            subModuleItem.permission = option;
          }
        });

        Object.values(module?.subModules?.TMP?.subModules || {}).forEach(
          (template) => {
            // eslint-disable-next-line no-param-reassign
            template.permission = option;
          }
        );
        break;

      default:
        break;
    }

    setModuleSubModules(cloneDeep(updatedModuleSubModules));
  };

  const transformedPermissionsObject = {};

  /**
   * @function transformPermissionJsonObject
   * @description Transforms the permissionJson object of a selectedGroupItem and populates the transformedPermissionsObject.
   * @param {Object} selectedGroupItem - The selected group item containing permissionJson.
   */

  function transformPermissionJsonObject(selectedGroupItem) {
    Object.keys(selectedGroupItem?.permissionJson).forEach((key) => {
      if (key.includes('_')) {
        const parts = key.split('_');
        const newKey = parts[parts.length - 1];

        if (newKey === 'template') {
          const templateKeyName =
            (parts?.includes('ModelInventory') && 'MItemplate') ||
            (parts?.includes('ModelAssociation') && 'MAtemplate');
          transformedPermissionsObject[templateKeyName] =
            selectedGroupItem?.permissionJson?.[key];
        } else {
          transformedPermissionsObject[newKey] =
            selectedGroupItem?.permissionJson?.[key];
        }
      } else {
        const parts = key.split(' ');
        const newKey = parts[0];
        transformedPermissionsObject[newKey] =
          selectedGroupItem?.permissionJson?.[key];
      }
    });
  }

  /**
   * @function iterateNestedObject
   * @description Recursively iterates through an object and updates the 'permission' property based on transformedPermissionsObject.
   * @param {Object} obj - The object to iterate and update permissions.
   */

  function iterateNestedObject(obj) {
    // Iterating obj to change the permissions from permissionJson obj

    if (typeof obj === 'object') {
      Object.keys(obj).forEach((key) => {
        if (key === 'permission') {
          // If obj.key is not present in permissionJson then we are adding default as View (default as None for templates).

          // eslint-disable-next-line no-nested-ternary
          const updatedPermission = transformedPermissionsObject[obj?.key]
            ? transformedPermissionsObject[obj?.key]
            : obj?.objKey?.startsWith('TMP_')
            ? 'None'
            : 'View';

          // eslint-disable-next-line no-param-reassign
          obj[key] = updatedPermission; // Update the permission property
        }

        iterateNestedObject(obj[key]);
      });
    }
  }

  /**
   * Recursively update module permissions in an object hierarchy from bottom to top.
   * From bottom to top because we want to make sure we are updating children's permissions first so parents permissions will change accordingly for all levels of hierarchy
   * @param {Object} obj - The object to iterate over.
   * @returns {Object} - The modified object with updated module permissions.
   */

  function updateModulePermissions(obj) {
    let result = {};

    if (typeof obj === 'object') {
      Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === 'object') {
          // eslint-disable-next-line no-param-reassign
          obj[key] = updateModulePermissions(obj[key]);
        }
      });

      if (obj.subModules) {
        // eslint-disable-next-line no-param-reassign
        obj.permission = calculateModulePermission(obj);
      }

      result = obj;
    }

    return result;
  }

  useEffect(() => {
    switch (mode) {
      case 'create': {
        const initialDefaultPermissionsModules = addPermissionKey(
          cloneDeep(MODULE_SUB_MODULE)
        );
        const updatedModuleSubModule = addTemplateModule(
          initialDefaultPermissionsModules
        );
        setModuleSubModules(cloneDeep(updatedModuleSubModule));
        break;
      }
      case 'edit': {
        const initialDefaultPermissionsModulesEdit = addPermissionKey(
          cloneDeep(MODULE_SUB_MODULE)
        );
        const updatedModuleSubModuleEdit = addTemplateModule(
          initialDefaultPermissionsModulesEdit
        );

        if (selectedGroup) {
          formikForm.setValues({
            groupName: selectedGroup?.groupName,
            groupDescription: selectedGroup?.description,
            userList: selectedGroup?.users?.map((item) => ({
              label: item?.username,
              value: item?.userId,
            })),
            adminList: selectedGroup?.admin_users?.map((item) => ({
              label: item?.username,
              value: item?.userId,
            })),
          });

          if (selectedGroup?.permissionJson) {
            // Transforming the permission object received from backend.
            transformPermissionJsonObject(selectedGroup);

            // Iterate through every nested module and subModules and updating the permission according to backend permission json
            iterateNestedObject(updatedModuleSubModuleEdit);

            let result = {};
            // Iterate through every nested module and subModules from bottom to top and updating the all modules permissions according to newly added objects (i.e templates or modules)
            result = updateModulePermissions(
              cloneDeep(updatedModuleSubModuleEdit)
            );

            setModuleSubModules({ ...cloneDeep(result) });
          }
        }
        break;
      }
      default:
        break;
    }
  }, [mode, selectedGroup, templateList]);

  const renderPermissions = (
    permission,
    moduleKey,
    subModuleKey,
    isTemplate = false
  ) => {
    const options = subModuleKey
      ? SUB_MODULE_PERMISSIONS_OPTIONS?.[subModuleKey] ||
        SUB_MODULE_PERMISSIONS_OPTIONS?.Default
      : MODULE_PERMISSIONS_OPTIONS?.[moduleKey] ||
        MODULE_PERMISSIONS_OPTIONS?.Default;

    return (
      <Box ml={2}>
        <Stack direction="row" spacing={0.5}>
          {options.map((option, index) => (
            <div key={option}>
              <Typography
                onClick={(e) => {
                  // Custom permission button should be non clickable
                  e.stopPropagation();
                  if (option !== 'Custom') {
                    handlePermissionChange(
                      moduleKey,
                      subModuleKey,
                      option,
                      isTemplate
                    );
                  }
                }}
                sx={{
                  color:
                    permission === option
                      ? (theme) => theme.palette.primary.dark
                      : (theme) => theme.palette.other.white2,
                  cursor: 'pointer',
                }}
              >
                {option}
              </Typography>
              {/* This condition is for not showing slash(/) for last element. */}
              {options?.length !== index + 1 && (
                <Typography
                  sx={{
                    color: (theme) => theme.palette.other.white2,
                  }}
                >
                  /
                </Typography>
              )}
            </div>
          ))}
        </Stack>
      </Box>
    );
  };
  /**
   * Handles the click event for a specific action.
   * Validates the form and updates the step or submits the form accordingly.
   * @function
   * @name handleClick
   * @returns {void}
   */
  const handleClick = () => {
    formikForm.validateForm().then((validate) => {
      if (
        formikForm.values.groupDescription.length <= 254 &&
        formikForm.values.groupName.length <= 150 &&
        Object.keys(validate).length === 0
      ) {
        setStep('second');
      } else {
        formikForm.handleSubmit();
      }
    });
  };

  //  Removing the admin if we remove it from users list
  useEffect(() => {
    const filteredAdminList = formikForm?.values?.adminList?.filter(
      (adminUser) => {
        const output = formikForm?.values?.userList?.find(
          (user) => user?.value === adminUser?.value
        );
        return output;
      }
    );
    // Update the adminList only if it has changed
    if (filteredAdminList?.length !== formikForm?.values?.adminList?.length) {
      formikForm.setFieldValue('adminList', filteredAdminList);
    }
  }, [formikForm?.values?.userList]);

  return (
    <Box>
      <Dialog
        maxWidth="sm"
        fullWidth={true}
        open={open}
        onClose={() => {
          handleCloseModal();
        }}
        TransitionComponent={DialogTransition}
        sx={{
          '& .MuiPaper-root.MuiDialog-paper': {
            'padding-bottom': '42px',
          },
        }}
      >
        <Grid container xs={12}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton
                onClick={() => {
                  handleCloseModal();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="space-around"
              alignItems="center"
              mb={2}
            >
              <Typography variant="h3">
                {mode === 'create'
                  ? STEP_TITLES_CREATE_MODE[step]
                  : STEP_TITLES_EDIT_MODE[step]}
              </Typography>
            </Box>
          </Grid>
        </Grid>
        <Grid
          container
          rowSpacing={5}
          xs={12}
          display={step === 'first' ? 'block' : 'none'}
        >
          <Grid
            item
            container
            xs={12}
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={6}>
              <TextField
                required
                name="groupName"
                value={formikForm?.values?.groupName}
                {...formikForm.getFieldProps('groupName')}
                placeholder="Enter group name."
                label="Group name"
                helperText={
                  formikForm?.errors?.groupName &&
                  formikForm?.touched?.groupName
                    ? formikForm?.errors?.groupName
                    : null
                }
                error={
                  Boolean(formikForm?.errors?.groupName) &&
                  formikForm?.touched?.groupName
                }
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                required
                name="groupDescription"
                value={formikForm?.values?.groupDescription}
                {...formikForm.getFieldProps('groupDescription')}
                placeholder="Enter group description."
                label="Group description"
                helperText={
                  formikForm?.errors?.groupDescription &&
                  formikForm?.touched?.groupDescription
                    ? formikForm?.errors?.groupDescription
                    : null
                }
                error={
                  Boolean(formikForm?.errors?.groupDescription) &&
                  formikForm?.touched?.groupDescription
                }
              />
            </Grid>
            <Grid item xs={12}>
              <Multiselect
                name="userList"
                options={
                  userList?.length > 0
                    ? userList?.map((user) => {
                        return { label: user?.username, value: user?.user_id };
                      })
                    : []
                }
                value={
                  isEmpty(formikForm?.values?.userList)
                    ? []
                    : formikForm?.values?.userList
                }
                getOptionLabel={(option) => option?.label}
                isOptionEqualToValue={(option, val) =>
                  option?.label === val?.label
                }
                noOptionsMessage="No user found."
                popupIcon={<DropdownArrow />}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Users"
                    placeholder={
                      formikForm?.values?.userList.length ? '' : 'Select users.'
                    }
                    helperText={
                      formikForm.touched?.userList &&
                      formikForm.errors?.userList
                        ? formikForm.errors?.userList
                        : null
                    }
                    error={
                      formikForm.touched?.userList &&
                      Boolean(formikForm.errors?.userList)
                    }
                  />
                )}
                onChange={(value) => {
                  formikForm?.setFieldValue('userList', value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Multiselect
                name="adminList"
                options={
                  formikForm?.values?.userList?.length > 0
                    ? formikForm?.values?.userList
                    : []
                }
                value={
                  isEmpty(formikForm?.values?.adminList)
                    ? []
                    : formikForm?.values?.adminList
                }
                getOptionLabel={(option) => option?.label}
                isOptionEqualToValue={(option, val) =>
                  option?.label === val?.label
                }
                noOptionsMessage="No user found."
                popupIcon={<DropdownArrow />}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Admins"
                    placeholder={
                      formikForm?.values?.adminList.length
                        ? ''
                        : 'Select admins.'
                    }
                    helperText={
                      formikForm.touched?.adminList &&
                      formikForm.errors?.adminList
                        ? formikForm.errors?.adminList
                        : null
                    }
                    error={
                      formikForm.touched?.adminList &&
                      Boolean(formikForm.errors?.adminList)
                    }
                  />
                )}
                onChange={(value) => {
                  formikForm?.setFieldValue('adminList', value);
                }}
              />
            </Grid>
            <Grid item xs={12}>
              <Stack direction="row" spacing={3} justifyContent="center" mt={3}>
                <Button
                  onClick={() => {
                    formikForm.handleReset();
                  }}
                >
                  RESET
                </Button>
                <Button onClick={handleClick} variant="contained">
                  NEXT
                </Button>
              </Stack>
            </Grid>
          </Grid>
        </Grid>

        {/* Second Step */}
        <Grid container display={step === 'second' ? 'block' : 'none'}>
          <Grid xs={12} item textAlign="center">
            <Box mb={2}>
              <Typography textAlign="center">
                Please select permissions.
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={2}>
              <Grid container spacing={2}>
                {Object.values(moduleSubModules).map((module) => (
                  <Accordion key={module.objKey}>
                    <AccordionSummary
                      expandIcon={<KeyboardArrowRightIcon />}
                      aria-controls={module.objKey}
                    >
                      <Box display="flex" alignItems="center">
                        <Typography>{module.label}</Typography>
                      </Box>
                      {renderPermissions(
                        module?.permission,
                        module?.objKey,
                        null
                      )}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Box ml={4}>
                        {Object.values(module?.subModules).map((subModule) => {
                          return subModule?.objKey === 'TMP' ? (
                            // Rendering Template Accordion
                            <Accordion key={subModule?.objKey}>
                              <AccordionSummary
                                expandIcon={<KeyboardArrowRightIcon />}
                                aria-controls={subModule?.objKey}
                                sx={{
                                  '&.MuiAccordionSummary-root': {
                                    paddingLeft: 0,
                                  },
                                }}
                              >
                                <Box display="flex" alignItems="center">
                                  <Typography>{subModule?.label}</Typography>
                                </Box>
                                {renderPermissions(
                                  subModule?.permission,
                                  module?.objKey,
                                  subModule?.objKey
                                )}
                              </AccordionSummary>
                              <AccordionDetails>
                                <Box ml={4}>
                                  {Object.values(subModule?.subModules)?.map(
                                    // eslint-disable-next-line no-shadow
                                    (subModule) => (
                                      <Box
                                        key={subModule?.objKey}
                                        display="flex"
                                      >
                                        <Typography
                                          mr={2}
                                          sx={{
                                            color: (theme) =>
                                              theme.palette.other.gray2,
                                          }}
                                        >
                                          {subModule.label} :
                                        </Typography>
                                        {renderPermissions(
                                          subModule?.permission,
                                          module?.objKey,
                                          subModule?.objKey,
                                          true
                                        )}
                                      </Box>
                                    )
                                  )}
                                </Box>
                              </AccordionDetails>
                            </Accordion>
                          ) : (
                            <Box key={subModule?.objKey} display="flex">
                              <Typography
                                mr={2}
                                sx={{
                                  color: (theme) => theme.palette.other.gray2,
                                }}
                              >
                                {subModule?.label} :
                              </Typography>
                              {renderPermissions(
                                subModule?.permission,
                                module?.objKey,
                                subModule?.objKey
                              )}
                            </Box>
                          );
                        })}
                      </Box>
                    </AccordionDetails>
                  </Accordion>
                ))}
              </Grid>
            </Box>
          </Grid>
          <Stack direction="row" spacing={3} justifyContent="center" mt={3}>
            <Button
              color="primary"
              onClick={() => {
                setStep('first');
              }}
            >
              BACK
            </Button>
            <Button
              color="primary"
              variant="contained"
              onClick={() => {
                setStep('third');
              }}
            >
              PREVIEW SCREEN
            </Button>
          </Stack>
        </Grid>

        <Grid container display={step === 'third' ? 'block' : 'none'}>
          <Grid item xs={12}>
            <Box pl={2} overflow="hidden" textOverflow="ellipsis">
              <Typography variant="body2" color="other.grey2">
                Group name:&nbsp;
              </Typography>
              <Typography variant="body1" sx={{ lineHeight: '19.07px' }}>
                {formikForm?.values?.groupName
                  ? formikForm.values.groupName
                  : 'Please add group name'}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={2} overflow="hidden" textOverflow="ellipsis">
              <Typography variant="body2" color="other.grey2">
                Description:&nbsp;
              </Typography>
              <Typography variant="body2">
                {formikForm.values.groupDescription
                  ? formikForm.values.groupDescription
                  : 'Please add group description'}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box p={2}>
              <Grid container spacing={2}>
                {formikForm?.values?.userList?.length > 0 ? (
                  formikForm?.values?.userList?.slice(0, 23).map((item) => (
                    <Grid key={item?.value} item xs={1.5} title={item?.label}>
                      <Avatar
                        sx={{
                          color: 'text.main',
                          backgroundColor: 'primary.main',
                        }}
                      >
                        {item?.label
                          .match(/(\b\S)?/g)
                          .join('')
                          .toUpperCase()}
                      </Avatar>
                    </Grid>
                  ))
                ) : (
                  <Typography variant="body2" ml="15px">
                    No users added
                  </Typography>
                )}
                {formikForm?.values?.userList?.length > 23 && (
                  <Grid item xs={1.5}>
                    <Avatar
                      sx={{
                        color: 'primary.main',
                        backgroundColor: 'secondary.main',
                      }}
                    >
                      {
                        // eslint-disable-next-line no-unsafe-optional-chaining
                        `${formikForm?.values?.userList?.length - 23}`
                      }
                    </Avatar>
                  </Grid>
                )}
              </Grid>
            </Box>
          </Grid>
          <Divider
            variant="fullWidth"
            sx={{
              borderColor: 'secondary.main',
            }}
          />
          <Grid item xs={12}>
            <Box p={2}>
              <Grid container spacing={2}>
                {Object.values(moduleSubModules).map((module) => (
                  <Grid item xs={12} container key={module?.key}>
                    <Grid item xs={5}>
                      <Typography variant="body2">{module?.label}</Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body3">
                        {module?.permission === 'View'
                          ? module?.permission
                          : '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body3">
                        {module?.permission === 'Manage'
                          ? module?.permission
                          : '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={2}>
                      <Typography variant="body3">
                        {module?.permission === 'None'
                          ? module?.permission
                          : '-'}
                      </Typography>
                    </Grid>
                    <Grid item xs={1}>
                      <Typography variant="body3">
                        {module?.permission === 'Custom'
                          ? module?.permission
                          : '-'}
                      </Typography>
                    </Grid>
                  </Grid>
                ))}
              </Grid>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Stack direction="row" spacing={2} justifyContent="center" mt={2}>
              <Button
                onClick={() => {
                  setStep('second');
                }}
              >
                BACK
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={formikForm.handleSubmit}
              >
                {mode === 'create' ? 'CREATE GROUP' : 'UPDATE GROUP'}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Dialog>
    </Box>
  );
};

CreateEditGroupModal.propTypes = {
  handleClose: PropTypes.func.isRequired,
  getGroupDetails: PropTypes.func.isRequired,
  createGroup: PropTypes.func.isRequired,
  updateGroup: PropTypes.func.isRequired,
  setSkipPageReset: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  mode: PropTypes.oneOf(['CREATE', 'EDIT']).isRequired,
  selectedGroup: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
    .isRequired,
  userList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
    .isRequired,
  templateList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
    .isRequired,
};
export default CreateEditGroupModal;
