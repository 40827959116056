/* eslint-disable no-underscore-dangle */
// @ts-nocheck
import axios from 'axios';

import SwalToast from '../components/Common/SwalTost';
import API_ENDPOINTS from '../const/ApiEndPoints';
import { encrypt, decrypt } from './cryptoUtils';

const refreshAccessToken = (refreshToken) => {
  const organisation = JSON.parse(localStorage.getItem('profile_data'));
  return axios.post(
    `${API_ENDPOINTS.MODULE_BASE_URL.AUTH + API_ENDPOINTS.AUTH.REFRESH_TOKEN}`,
    {
      refresh: refreshToken,
    },
    {
      headers: {
        org: organisation?.org,
      },
    }
  );
};
const logout = () => {
  localStorage.clear();
  sessionStorage.clear();
  window.location.reload();
};
export const inializeAxios = () => {
  const showLoaderEvent = new Event('showLoader', { bubbles: true });
  const hideLoaderEvent = new Event('hideLoader', { bubbles: true });
  let numberOfApiCallsPending = 0;
  const headersCommonOptions = {
    Accept: 'application/json',
    'Content-Type': 'application/json',
  };

  axios.defaults.headers.common = headersCommonOptions;

  // Cleaning the interceptor handlers so our interceptor dose not run two times.
  if (axios.interceptors.request.handlers.length > 0) {
    axios.interceptors.request.handlers = [];
  }
  if (axios.interceptors.response.handlers.length > 0) {
    axios.interceptors.response.handlers = [];
  }

  axios.interceptors.request.use((req) => {
    numberOfApiCallsPending += 1;

    const ORGANIZATION = JSON.parse(localStorage.getItem('profile_data'));

    if (
      req.loader !== false &&
      req?.url !==
        `${process.env.REACT_APP_VAULT_BASE_URL}/vault/graphplot/getnodes/`
    ) {
      document.dispatchEvent(showLoaderEvent);
    }
    if (
      process.env.REACT_APP_ENABLE_ENCRYPTION === 'true' &&
      process.env.NODE_ENV === 'production' &&
      !req._alreadyEncrypted // to ensure that the encryption is only applied once
    ) {
      if (req?.params) {
        const data = encrypt(JSON.stringify(req.params));
        req.params = { data };
        req._alreadyEncrypted = true;
      }
      // If content type is multipart/form-data then we are just encrypting the data part and file part will remain same.
      if (
        req?.data &&
        req?.headers?.['Content-Type'] === 'multipart/form-data'
      ) {
        const data = encrypt(req?.data?.data);
        req.data = { ...req?.data, data };
        req._alreadyEncrypted = true;
      }
      if (req?.data && req?.headers?.['Content-Type'] === 'application/json') {
        const data = encrypt(JSON.stringify(req?.data));
        req.data = { data };
        req._alreadyEncrypted = true;
      }
    }
    if (
      req?.url ===
      `${API_ENDPOINTS.MODULE_BASE_URL.AUTH + API_ENDPOINTS.AUTH.REFRESH_TOKEN}`
    ) {
      return req;
    }
    const token = localStorage.getItem('access_token');
    // console.log(new URL(req.url).host.split(':')[0]);
    // console.log(window.location.host.split('.'));
    // console.log(token);
    if (
      token &&
      API_ENDPOINTS.HOSTS_NEEDS_TOKEN.includes(
        new URL(req.url).host.split(':')[0]
      )
    ) {
      req.headers.authorization = `JWT ${token}`;
    }
    if (
      API_ENDPOINTS.HOSTS_NEEDS_TOKEN.includes(
        new URL(req.url).host.split(':')[0]
      )
    ) {
      [req.headers.org] = [`${ORGANIZATION?.org}`];
    }
    return req;
  });

  axios.interceptors.response.use(
    (response) => {
      numberOfApiCallsPending -= 1;
      if (numberOfApiCallsPending === 0) {
        setTimeout(() => {
          document.dispatchEvent(hideLoaderEvent);
        }, 400);
      }
      if (
        process.env.REACT_APP_ENABLE_ENCRYPTION === 'true' &&
        process.env.NODE_ENV === 'production'
      ) {
        const responseData = decrypt(response?.data?.data);
        if (responseData) {
          response.data = JSON.parse(responseData);
        }
      }
      return response;
    },
    async (error) => {
      numberOfApiCallsPending -= 1;
      if (numberOfApiCallsPending === 0) {
        setTimeout(() => {
          document.dispatchEvent(hideLoaderEvent);
        }, 400);
      }
      if (
        error.config.url ===
        `${
          API_ENDPOINTS.MODULE_BASE_URL.AUTH + API_ENDPOINTS.AUTH.REFRESH_TOKEN
        }`
      ) {
        logout();
        return Promise.reject(error);
      }
      if (error.response?.status === 401 && !error?.config?._retry) {
        const refreshToken = localStorage.getItem('refresh_token');
        if (refreshToken) {
          SwalToast({
            icon: 'info',
            // eslint-disable-next-line no-nested-ternary
            title:
              'Please wait your token might have expired. We are trying to refresh the token and resend the request.',
          });
        } else {
          logout();
          return Promise.reject(error);
        }
        // eslint-disable-next-line no-param-reassign
        error.config._retry = true;
        const res = await refreshAccessToken(refreshToken);
        if (res?.data?.refresh && res?.data?.access) {
          SwalToast({
            icon: 'success',
            // eslint-disable-next-line no-nested-ternary
            title: 'Token refreshed successfully. Resending API requests.',
          });
          localStorage.setItem('refresh_token', res?.data?.refresh);
          localStorage.setItem('access_token', res?.data?.access);
          return axios.request(error.config);
        }
        SwalToast({
          icon: 'error',
          // eslint-disable-next-line no-nested-ternary
          title: 'Failed to refresh the token. Invalid API response.',
        });
        logout();
        return Promise.reject(error);
      }
      if (!error?.config?.suppressErrorToast) {
        SwalToast({
          icon: 'error',
          // eslint-disable-next-line no-nested-ternary
          title: error?.response?.data?.msg
            ? typeof error?.response?.data?.msg === 'string'
              ? error.response.data.msg
              : JSON.stringify(error.response.data.msg)
            : error.message,
        });
        return Promise.reject(error);
      }
      if (typeof error?.config?.errorCallback === 'function') {
        error?.config?.errorCallback(error);
      }
    }
  );
};
export default inializeAxios;
