/* eslint-disable react/prop-types */
// @ts-nocheck
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import TablePagination from '@mui/material/TablePagination';
import { FixedSizeList } from 'react-window';
import _ from 'lodash';
import { ThemeProvider, createTheme, useTheme } from '@mui/material/styles';
import { Box, Checkbox, Typography, Pagination } from '@mui/material';

import {
  useGlobalFilter,
  usePagination,
  useRowSelect,
  useSortBy,
  useTable,
  useFlexLayout,
  useResizeColumns,
  useGroupBy,
  useExpanded,
  useColumnOrder,
} from 'react-table';
import { v4 as uuidv4 } from 'uuid';

import SortButtonArrowIcon from '../../assets/images/SortButtonArrowIcon.svg';
import { ContextMenus } from './ContextMenus';

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;
    useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);
    return <Checkbox sx={{ padding: 0 }} ref={resolvedRef} {...rest} />;
  }
);
IndeterminateCheckbox.propTypes = {
  indeterminate: PropTypes.bool.isRequired,
};
const ReactMuiTablePaginationActions = (props) => {
  const { count, page, rowsPerPage, onPageChange } = props;

  const handlePageChange = (event, _page) => {
    onPageChange(event, _page - 1);
  };
  return (
    <Box sx={{ flexShrink: 0, marginLeft: (theme) => theme.spacing(2.5) }}>
      <Pagination
        count={
          count <= rowsPerPage && count / rowsPerPage === 0
            ? count / rowsPerPage
            : Math.max(0, Math.ceil(count / rowsPerPage))
        }
        page={page + 1}
        onChange={handlePageChange}
        color="primary"
        size="small"
        boundaryCount={3}
      />
    </Box>
  );
};

ReactMuiTablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};
const ROW_LEVEL_HOVER_OPTIONS_DEFAULT_STYLE = {
  position: 'absolute',
  float: 'right',
  right: 0,
  height: 'inherit',
  display: 'none',
};
const defaultPropGetter = () => ({});
const ReactMuiTableListView = ({
  columns,
  data,
  getHeaderProps = defaultPropGetter,
  getColumnProps = defaultPropGetter,
  getRowProps = defaultPropGetter,
  getCellProps = defaultPropGetter,
  rowLevelOnHoverOptions,
  externalCustomTheme,
  rowSelectionConfig,
  onTableStateChange,
  enableRowSelection,
  toggle,
  enablePagination = false,
  isAllOptionDisabled = false,
  rowsPerPageOptions,
  // pageCount,
  pageCount: controlledPageCount,
  initialPageSize = 10,
  renderRowSubComponent,
  initialGlobalFilter,
  autoResetExpanded = true,
  noSearchResultMessage = 'No data result found.',
  skipPageReset,
  rowLevelRightClickMenus = [],
  enableControlledPagination = false,
  fetchData,
  dataLength,
  labelDisplayedRows,
  showPaginationAction = true,
  enableVirtualization = false,
  maxHeightForVirtualization = document.documentElement.clientHeight * 0.65,
  itemHeightForVirtualization = 40,
  hiddenColumns = [],
}) => {
  const theme = useTheme();
  // const { show } = useContextMenu();
  const CUSTOM_THEME = {
    components: {
      MuiTableBody: {
        styleOverrides: {
          root: {
            borderLeft: 0,
            borderRight: 0,
            borderBottom: 0,
            borderTop: 0,
            borderStyle: 'solid',
            boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.12)',
          },
        },
      },
      MuiTableHead: {
        styleOverrides: {
          borderBottom: 0,
          boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.12)',
        },
      },
      MuiCheckbox: {
        styleOverrides: {
          root: {
            '& .MuiSvgIcon-root': {
              fontSize: '20px',
            },
            // padding: 0,
          },
        },
      },
      MuiTableCell: {
        styleOverrides: {
          head: {
            justifyContent: 'flex-start',
            borderBottom: 0,
            backgroundColor: theme.palette.primary.contrastText,
            color: theme.palette.primary.main,
            fontWeight: 700,

            '& .MuiCheckbox-root': {
              color: theme.palette.primary.main,
            },
            '& .Mui-checked': {
              color: theme.palette.primary.main,
            },
          },
          root: {
            borderBottom: '1px solid ',
            minHeight: 10,
            background: theme.palette.other.white,
          },
          sizeMedium: {
            lineHeight: 1,
            padding: '14px !important',
            borderColor: theme.palette.other.gray4,
          },
        },
      },
    },
  };
  const newTheme = createTheme(
    _.merge(_.cloneDeep(theme), CUSTOM_THEME, externalCustomTheme)
  );
  // eslint-disable-next-line react/no-unstable-nested-components
  const CheckBoxCell = ({ row }) => {
    return (
      <div>
        <IndeterminateCheckbox
          {...row.getToggleRowSelectedProps({
            // eslint-disable-next-line no-use-before-define
            onChange: toggleRowSelection.bind(null, row),
          })}
        />
      </div>
    );
  };
  CheckBoxCell.propTypes = {
    row: PropTypes.oneOfType([PropTypes.object]).isRequired,
  };

  // eslint-disable-next-line react/no-unstable-nested-components
  const RowSelectionHeader = ({ getToggleAllRowsSelectedProps }) => {
    return (
      <Box display="flex" alignItems="center" textAlign="center">
        {rowSelectionConfig.type === 'multi-select' ? (
          <>
            <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
            <Typography variant="body3" ml={1} color="inherit">
              Select all
            </Typography>
          </>
        ) : (
          <Typography
            variant="body3"
            sx={{ fontWeight: 700 }}
            ml={1}
            color="inherit"
          >
            SELECT
          </Typography>
        )}
      </Box>
    );
  };
  RowSelectionHeader.propTypes = {
    getToggleAllRowsSelectedProps: PropTypes.func.isRequired,
  };
  const menuId = `react-mui-table-list-view-row-level-menu-${uuidv4()}`;
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    page,
    gotoPage,
    setPageSize,
    prepareRow,
    toggleAllRowsSelected,
    getToggleAllRowsSelectedHandler,
    setGlobalFilter,
    state: { pageIndex, pageSize, selectedRowIds, globalFilter },
  } = useTable(
    {
      columns,
      data,
      autoResetSelectedRows: false,
      initialState: {
        pageIndex: 0,
        pageSize: initialPageSize,
        globalFilter: initialGlobalFilter,
        hiddenColumns,
      },
      pageCount: controlledPageCount,
      manualPagination: enableControlledPagination,
      autoResetExpanded,
      autoResetPage: !skipPageReset,
    },
    useGlobalFilter,
    useFlexLayout,
    useResizeColumns,
    useColumnOrder,
    useGroupBy,

    useSortBy,
    useExpanded,
    usePagination,

    useRowSelect,

    (hooks) => {
      if (rowSelectionConfig.enable && rowSelectionConfig.mode === 'checkbox') {
        // eslint-disable-next-line no-shadow
        hooks.allColumns.push((columns) => [
          {
            id: 'selection',
            Header: RowSelectionHeader,
            width: 100,
            Cell: CheckBoxCell,
          },
          ...columns,
        ]);
      }
    }
  );

  // globalFilter effect should only be considered when controlled filtering is true else not.
  React.useEffect(() => {
    if (enableControlledPagination && fetchData) {
      fetchData(pageIndex, pageSize);
    }
  }, [enableControlledPagination, pageIndex, pageSize]);

  React.useEffect(() => {
    if (onTableStateChange) {
      onTableStateChange({
        pageIndex,
        selectedRowIds,
        getToggleAllRowsSelectedHandler,
        toggleAllRowsSelected,
        globalFilter,
        data,
        pageSize,
        gotoPage,
      });
    }
  }, [selectedRowIds, pageIndex, pageSize, globalFilter, data, gotoPage]);
  const toggleRow = () => {
    toggleAllRowsSelected(false);
  };
  useEffect(() => {
    setGlobalFilter(initialGlobalFilter);
  }, [initialGlobalFilter]);
  useEffect(() => {
    toggleRow();
  }, [toggle]);

  const allOptionValueSetter = (rowsPerPageOptionsParam, dataLengthParam) => {
    if (
      rowsPerPageOptionsParam &&
      [...rowsPerPageOptionsParam, 5, 10, 20, 50].includes(
        Number(dataLengthParam)
      )
    ) {
      return Number(dataLengthParam) + 1;
    }
    if ([5, 10, 20, 50].includes(Number(dataLengthParam))) {
      return Number(dataLengthParam) + 1;
    }
    return dataLengthParam;
  };

  const toggleRowSelection = React.useCallback(
    (row) => {
      if (rowSelectionConfig.enable) {
        if (rowSelectionConfig.type === 'single-select' && !row.isSelected) {
          toggleAllRowsSelected(false);
        }
        row.toggleRowSelected();
      }
    },
    [rowSelectionConfig, toggleAllRowsSelected]
  );

  // Virtualized rows Row Render function
  const VirtualizedRowsRenderer = React.useCallback(
    ({ index, style }) => {
      const row = (enablePagination ? page : rows)[index];
      prepareRow(row);
      return (
        <TableRow
          {...row.getRowProps([
            {
              style: {
                ...style,
                ...(rowSelectionConfig.enable &&
                rowSelectionConfig.selectedRowStyle &&
                Object.keys(selectedRowIds).includes(row.id)
                  ? rowSelectionConfig.selectedRowStyle
                  : {}),
              },
              ...(rowSelectionConfig.enable &&
              rowSelectionConfig.mode === 'row-click'
                ? { onClick: toggleRowSelection.bind(null, row) }
                : {}),
            },
            getRowProps(row, toggleAllRowsSelected),
          ])}
        >
          {row.cells.map((cell) => {
            return (
              // eslint-disable-next-line react/jsx-key
              <TableCell
                {...cell.getCellProps([
                  {
                    style: {
                      textOverflow: 'ellipsis',
                      overflow: 'hidden',
                      wordBreak: 'initial',
                      whiteSpace: 'nowrap',
                      cursor: 'pointer',
                    },
                    onClick: (e) => e.stopPropagation(),
                  },
                  getColumnProps(cell.column),
                  getCellProps(cell),
                ])}
                title={cell.value}
                align="left"
              >
                {
                  // eslint-disable-next-line no-nested-ternary
                  cell.isAggregated
                    ? // If the cell is aggregated, use the Aggregated
                      // renderer for cell
                      cell.render('Aggregated')
                    : cell.isPlaceholder
                    ? null // For cells with repeated values, render null
                    : // Otherwise, just render the regular cell
                      cell.render('Cell')
                }
              </TableCell>
            );
          })}
        </TableRow>
      );
    },
    [prepareRow, rows, page, toggleRowSelection, getCellProps]
  );

  return (
    <Box width="100%">
      <ThemeProvider theme={newTheme}>
        <TableContainer>
          {data?.length && columns?.length ? (
            <Table {...getTableProps()} size="medium">
              <TableHead>
                {headerGroups.map((headerGroup) => (
                  <TableRow
                    key={JSON.stringify(headerGroup)}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    {headerGroup.headers.map((column, key) => (
                      <TableCell
                        // eslint-disable-next-line react/no-array-index-key
                        key={key}
                        align="center"
                        variant="head"
                        sx={{ textTransform: 'inherit' }}
                        {...column.getHeaderProps([
                          getColumnProps(),
                          getHeaderProps(),
                        ])}
                      >
                        {column.render('Header')}

                        {!['selection', 'expander', 'pk', 'view', ''].includes(
                          column.id
                        ) ? (
                          <Box
                            // @ts-ignore
                            {...(column.id === 'selection'
                              ? {}
                              : column.getSortByToggleProps())}
                          >
                            {
                              // eslint-disable-next-line no-nested-ternary
                              column.isSorted ? (
                                <TableSortLabel
                                  active={column.isSorted}
                                  // react-table has a unsorted state which is not treated here
                                  direction={
                                    column.isSortedDesc ? 'desc' : 'asc'
                                  }
                                />
                              ) : !column.disableSortBy ? (
                                <Box>
                                  <img
                                    src={SortButtonArrowIcon}
                                    alt="SortButtonArrowIcon"
                                    height={11}
                                    width={11}
                                  />
                                </Box>
                              ) : null
                            }
                          </Box>
                        ) : null}
                        <div
                          {...column.getResizerProps()}
                          className={`resizer ${
                            column.isResizing ? 'isResizing' : ''
                          }`}
                        />
                      </TableCell>
                    ))}
                  </TableRow>
                ))}
              </TableHead>

              <TableBody {...getTableBodyProps()}>
                {enableVirtualization ? (
                  <FixedSizeList
                    height={
                      // eslint-disable-next-line no-nested-ternary
                      enablePagination
                        ? pageSize < data.length
                          ? pageSize * itemHeightForVirtualization
                          : maxHeightForVirtualization
                        : maxHeightForVirtualization
                    }
                    itemCount={(enablePagination ? page : rows).length}
                    itemSize={itemHeightForVirtualization}
                    width="100%"
                    style={{
                      overflowX: 'hidden',
                      overflowY: 'auto',
                    }}
                  >
                    {VirtualizedRowsRenderer}
                  </FixedSizeList>
                ) : (
                  (enablePagination ? page : rows)?.map((row, i) => {
                    prepareRow(row);
                    return (
                      <>
                        <TableRow
                          // eslint-disable-next-line react/no-array-index-key
                          key={i}
                          {...row.getRowProps([
                            getRowProps(row, theme, toggleAllRowsSelected),
                          ])}
                          sx={{
                            '&:hover': {
                              [`& .row-level-options-on-hover-${i}`]: {
                                display: 'block',
                              },
                            },
                          }}
                        >
                          {!enableRowSelection
                            ? rowLevelOnHoverOptions({
                                className: `row-level-options-on-hover-${i}`,
                                containerSx:
                                  ROW_LEVEL_HOVER_OPTIONS_DEFAULT_STYLE,
                                row,
                                rowIndex: i,
                              })
                            : ''}
                          {row.cells.map((cell, index) => {
                            return (
                              <TableCell
                                // eslint-disable-next-line react/no-array-index-key
                                key={index}
                                {...cell.getCellProps([
                                  {
                                    style: {
                                      textOverflow: 'ellipsis',
                                      overflow: 'hidden',
                                      wordBreak: 'initial',
                                      whiteSpace: 'nowrap',
                                      cursor: 'pointer',
                                    },
                                    onClick: (e) => e.stopPropagation(),
                                  },
                                  getColumnProps(cell.column),
                                  getCellProps(cell),
                                ])}
                              >
                                {cell.render('Cell')}
                              </TableCell>
                            );
                          })}
                        </TableRow>
                        {row.isExpanded ? (
                          <TableRow>
                            <TableCell>
                              {renderRowSubComponent({ row })}
                            </TableCell>
                          </TableRow>
                        ) : null}
                      </>
                    );
                  })
                )}
              </TableBody>
            </Table>
          ) : null}
          {rows?.length <= 0 ? (
            <Box
              display="flex"
              style={{ height: '100px' }}
              flexGrow={1}
              alignItems="center"
              flexDirection="column"
              justifyContent="center"
            >
              <Typography variant="subtitle1">
                {noSearchResultMessage}
              </Typography>
            </Box>
          ) : null}
        </TableContainer>
        <Box width="100%" overflow="auto">
          {enablePagination && (
            <TablePagination
              rowsPerPageOptions={[
                ...(rowsPerPageOptions
                  ? rowsPerPageOptions.map((ele) => {
                      return { label: `${ele} rows`, value: ele };
                    })
                  : [5, 10, 20, 50].map((ele) => {
                      return { label: `${ele} rows`, value: ele };
                    })),
                ...(isAllOptionDisabled
                  ? []
                  : [
                      {
                        label: 'All',
                        value: enableControlledPagination
                          ? allOptionValueSetter(rowsPerPageOptions, dataLength)
                          : allOptionValueSetter(
                              rowsPerPageOptions,
                              rows.length
                            ),
                      },
                    ]),
              ]}
              colSpan={columns?.length}
              count={enableControlledPagination ? dataLength : rows.length}
              rowsPerPage={pageSize}
              page={pageIndex}
              SelectProps={{
                inputProps: {
                  'aria-label': 'rows per page',
                },
                native: true,
              }}
              onPageChange={(event, newPage) => {
                gotoPage(newPage);
              }}
              onRowsPerPageChange={(event) => {
                setPageSize(Number(event.target.value));
                gotoPage(0);
              }}
              labelRowsPerPage="Show:"
              {...(labelDisplayedRows ? { labelDisplayedRows } : {})}
              ActionsComponent={
                showPaginationAction
                  ? ReactMuiTablePaginationActions
                  : undefined
              }
            />
          )}
        </Box>
        {rowLevelRightClickMenus?.length ? (
          <ContextMenus
            menus={rowLevelRightClickMenus}
            additionalData={{ test: '1' }}
            menuId={menuId}
          />
        ) : null}
      </ThemeProvider>
    </Box>
  );
};

ReactMuiTableListView.defaultProps = {
  getHeaderProps: () => ({}),
  getColumnProps: () => ({}),
  // getRowProps: () => ({}),
  rowLevelOnHoverOptions: () => ({}),
  onRowSelect: () => ({}),
  externalCustomTheme: {},
  rowSelectionConfig: {
    enable: false,
    mode: 'checkbox',
    showDeleteOnSelected: false,
    type: 'multi-select',
  },
  onTableStateChange: () => ({}),
  enableRowSelection: false,
  toggle: false,
  rowLevelRightClickMenus: [],
  fetchData: () => {},
  enableControlledPagination: false,
  labelDisplayedRows: null,
  showPaginationAction: true,
  contextMenuCallback: () => {},
  hiddenColumns: [],
};

ReactMuiTableListView.propTypes = {
  columns: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
    .isRequired,
  data: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  getHeaderProps: PropTypes.func,
  getColumnProps: PropTypes.func,
  getRowProps: PropTypes.func.isRequired,
  rowLevelOnHoverOptions: PropTypes.func,
  onTableStateChange: PropTypes.func,
  onRowSelect: PropTypes.func,
  enableRowSelection: PropTypes.bool,
  toggle: PropTypes.bool,
  externalCustomTheme: PropTypes.oneOfType([PropTypes.object]),
  rowSelectionConfig: PropTypes.oneOfType([PropTypes.object]),
  rowLevelRightClickMenus: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object])
  ),
  fetchData: PropTypes.func,
  enableControlledPagination: PropTypes.bool,
  dataLength: PropTypes.number.isRequired,
  labelDisplayedRows: PropTypes.func,
  showPaginationAction: PropTypes.bool,
  contextMenuCallback: PropTypes.func,
  hiddenColumns: PropTypes.arrayOf(PropTypes.string),
};

export default ReactMuiTableListView;
