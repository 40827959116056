// @ts-nocheck
import React from 'react';
import UserManagement from './views/UserManagement/UserManagement';

const ModelInventory = React.lazy(
  () => import('./views/ModelInventory/ModelInventory')
);
const Dashboard = React.lazy(() => import('./views/ModelInventory/Dashboard'));
// const ImportDataFromExcel = React.lazy(
//   () => import('./views/ModelInventory/ImportDataFromExcel')
// );
const BulkUpload = React.lazy(
  () => import('./views/BulkActions/BulkUpload/BulkUpload')
);
const BulkTransition = React.lazy(
  () => import('./views/BulkActions/BulkTransition/BulkTransition')
);
const ModelAssociation = React.lazy(
  () => import('./views/ModelAssociation/ModelAssociation')
);
const DocumentInventory = React.lazy(
  () => import('./views/DocumentInventory/DocumentInventory')
);
const EditModelAssociation = React.lazy(
  () => import('./views/ModelAssociation/EditModelAssociation')
);
const ModelValidation = React.lazy(
  () => import('./views/ModelAssociation/ModelValidation')
);
const Configuration = React.lazy(
  () => import('./views/Configuration/Configuration')
);
const NewTemplate = React.lazy(
  () => import('./views/Configuration/CreateEditTemplate')
);
const TemplateList = React.lazy(
  () => import('./views/Configuration/TemplateList')
);
const GlobalAttributes = React.lazy(
  () => import('./views/Configuration/GlobalAttributes')
);
const EditModelDetails = React.lazy(
  () => import('./views/ModelInventory/EditModelDetails')
);
const WorkFlowList = React.lazy(
  () => import('./views/Configuration/Workflow/WorkFlowList')
);
const EditWorkFlow = React.lazy(
  () => import('./views/Configuration/Workflow/EditWorkFlow')
);
const Rules = React.lazy(() => import('./views/RulesAndReports/Rules'));

const Reports = React.lazy(() => import('./views/RulesAndReports/Reports'));
const DocumentTemplate = React.lazy(
  () => import('./views/Configuration/Documentation/DocumentTemplate')
);
const DynamicChart = React.lazy(
  () => import('./views/Configuration/DynamicChart/DynamicChart')
);
const AutomationList = React.lazy(
  () => import('./views/Configuration/Automation/AutomationList')
);
const CreateAutomation = React.lazy(
  () => import('./views/Configuration/Automation/CreateAutomation')
);
const NotAuthorized = React.lazy(
  () => import('./components/Common/NotAuthorized')
);

const ModelRequest = React.lazy(
  () => import('./views/ModelInventory/ModelRequest/ModelRequest')
);
const EditModelRequest = React.lazy(
  () => import('./views/ModelInventory/ModelRequest/EditModelRequest')
);
const Routes = [
  {
    name: 'Model Inventory',
    path: 'my-model',
    key: 'modelInventory',
    element: <ModelInventory />,
    permission: 'ModelInventory_modelInventory',
  },
  {
    name: 'Edit Model ',
    key: 'editModel',
    path: 'my-model/:uniqueId',
    element: <EditModelDetails />,
  },

  {
    name: 'Dashboard',
    key: 'dashboard',
    path: 'dashboard',
    element: <Dashboard />,
    permission: 'ModelInventory_dashboard',
  },
  // {
  //   name: 'Import From Excel',
  //   key: 'importFromExcel',
  //   path: 'model-inventory/import-from-excel',
  //   element: <ImportDataFromExcel />,
  // },
  {
    name: 'Bulk Upload',
    key: 'bulkUpload',
    path: 'bulk-upload',
    element: <BulkUpload />,
    permission: 'BulkActions_bulkUpload',
  },
  {
    name: 'Bulk Transition',
    key: 'bulkTransition',
    path: 'bulk-transition',
    element: <BulkTransition />,
    permission: 'BulkActions_bulkTransition',
  },
  {
    path: 'model-artifact',
    element: <ModelAssociation />,
    permission: 'ModelAssociation',
  },
  {
    path: 'model-artifact/:uniqueId',
    element: <EditModelAssociation />,
  },
  {
    path: 'model-validation',
    element: <ModelValidation />,
  },
  {
    path: 'configuration',
    element: <Configuration />,
  },
  {
    path: 'templates/create-template',
    element: <NewTemplate />,
  },
  {
    path: 'templates/edit-template',
    element: <NewTemplate />,
  },
  {
    path: 'workflows',
    element: <WorkFlowList />,
    permission: 'Configuration_workflow',
  },
  {
    path: 'workflows/edit-workflow',
    element: <EditWorkFlow />,
  },
  {
    path: 'templates',
    element: <TemplateList />,
    permission: 'Configuration_templateList',
  },
  {
    path: 'global-attributes',
    element: <GlobalAttributes />,
    permission: 'Configuration_globalAttributes',
  },
  {
    path: 'rules',
    element: <Rules />,
    permission: 'RulesReports_rules',
  },
  {
    path: 'report',
    element: <Reports />,
    permission: 'RulesReports_report',
  },
  {
    path: 'user-management',
    element: <UserManagement />,
    permission: 'AdministrativeOptions_userManagement',
  },
  {
    name: 'Document Inventory',
    key: 'DocumentInventory',
    path: 'document-inventory',
    element: <DocumentInventory />,
    permission: 'DocumentInventory_documentInventory',
  },
  {
    name: 'Document Template',
    key: 'DocumentTemplate',
    path: 'document-template',
    element: <DocumentTemplate />,
    permission: 'Configuration_documentTemplate',
  },
  {
    name: 'Dynamic Chart',
    key: 'dynamicChart',
    path: 'dynamic-chart',
    element: <DynamicChart />,
    permission: 'Configuration_dynamicChart',
  },
  {
    name: 'Automation',
    key: 'automation',
    path: 'automation',
    element: <AutomationList />,
    permission: 'Configuration_automation',
  },
  {
    name: 'Create Automation',
    key: 'createAutomation',
    path: 'automation/create-automation',
    element: <CreateAutomation />,
    permission: 'Configuration_automation',
  },
  {
    name: 'Update Automation',
    key: 'updateAutomation',
    path: 'automation/update-automation',
    element: <CreateAutomation />,
    permission: 'Configuration_automation',
  },
  {
    name: ' NotAuthorized',
    key: 'notAuthorized',
    path: 'not-authorized',
    element: <NotAuthorized />,
  },
  {
    name: 'Model Request',
    path: 'model-request',
    key: 'modelRequest',
    element: <ModelRequest />,
    permission: 'ModelInventory_modelRequest',
  },
  {
    name: 'Edit Model Request ',
    key: 'editModelRequest',
    path: 'model-request/:uniqueId',
    element: <EditModelRequest />,
  },
  // {
  //   path: '/workflows/model-change-request',
  //   element: <ModelChangeRequest />,
  // },
  // {
  //   path: '/workflows/model-validation',
  //   element: <ModelValidation />,
  // },
  // {
  //   path: '/reporting',
  //   element: <Reporting />,
  // },
  // {
  //   path: '/contact-us',
  //   element: <ContactUs />,
  // },
];
export { Routes };
