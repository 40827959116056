// @ts-nocheck
import React from 'react';
import {
  Box,
  Dialog,
  Grid,
  IconButton,
  Typography,
  Button,
  Stack,
} from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import PropTypes from 'prop-types';
import DialogTransition from '../Transition/DialogTransition';

const ConfirmModal = ({
  open = false,
  handleClose = () => {},
  confirm = () => {},
  alertLabelText = '',
  confirmButtonLabel = 'Yes',
  cancelButtonLabel = 'No',
}) => {
  return (
    <Box>
      <Dialog
        maxWidth="xs"
        open={open}
        onClose={handleClose}
        sx={{
          '& .MuiPaper-root': {
            'padding-bottom': '24px',
          },
        }}
        TransitionComponent={DialogTransition}
      >
        <Grid container>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  handleClose();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
            mb={3}
          >
            <Box
              display="flex"
              justifyContent="center"
              alignItems="center"
              mt={1}
            >
              <Typography variant="h4" align="center">
                {alertLabelText}
              </Typography>
            </Box>
          </Grid>
          <Grid
            item
            xs={12}
            display="flex"
            justifyContent="center"
            alignItems="center"
          >
            <Stack direction="row" spacing={3} justifyContent="center" mt={3}>
              <Button onClick={confirm}>{confirmButtonLabel}</Button>
              <Button
                variant="contained"
                onClick={(e) => {
                  e.stopPropagation();
                  handleClose();
                }}
              >
                {cancelButtonLabel}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Dialog>
    </Box>
  );
};

ConfirmModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  confirm: PropTypes.func.isRequired,
  alertLabelText: PropTypes.string.isRequired,
  cancelButtonLabel: PropTypes.string.isRequired,
  confirmButtonLabel: PropTypes.string.isRequired,
};

export default ConfirmModal;
