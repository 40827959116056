// @ts-nocheck
/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  isAuthenticate: !!localStorage.getItem('access_token'),
  userData: JSON.parse(localStorage.getItem('user_data')),
  profileData: JSON.parse(localStorage.getItem('profile_data')),
  permissions: JSON.parse(localStorage.getItem('user_permissions')) || {},
};

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    loginSuccess: (state, action) => {
      localStorage.setItem('access_token', action.payload.token);
      localStorage.setItem('refresh_token', action.payload.refresh);
      localStorage.setItem('user_data', JSON.stringify(action.payload.user));
      localStorage.setItem(
        'profile_data',
        JSON.stringify(action.payload.profile)
      );
      state.isAuthenticate = true;
      state.userData = action.payload.user;
      state.profileData = action.payload.profile;
      localStorage.setItem(
        'remember_me',
        JSON.stringify(action.payload.remember)
      );
    },

    login(state, action) {
      state.push(action.payload);
    },
    logout(state) {
      state.pop();
    },
    setUserPermissions: (state, action) => {
      localStorage.setItem(
        'user_permissions',
        JSON.stringify(action.payload.permissions)
      );
      state.permissions = action.payload.permissions;
    },
  },
});
export const { login, logout, loginSuccess, setUserPermissions } =
  userSlice.actions;
export default userSlice.reducer;
