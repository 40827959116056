export const STEP_TITLES_CREATE_MODE = {
  first: 'Create Group',
  second: 'Manage Permissions',
  third: 'Preview',
};
export const STEP_TITLES_EDIT_MODE = {
  first: 'Edit Group',
  second: 'Edit Permissions',
  third: 'Preview',
};

export const MODULE_PERMISSIONS_OPTIONS = {
  MI: ['View', 'Manage', 'Custom'],
  MA: ['View', 'Manage', 'None', 'Custom'],
  RR: ['View', 'Manage', 'None', 'Custom'],
  CNF: ['View', 'Manage', 'None', 'Custom'],
  BA: ['View', 'Manage', 'None', 'Custom'],
  Default: ['View', 'Manage', 'None'],
};

export const SUB_MODULE_PERMISSIONS_OPTIONS = {
  DBC: ['Yes', 'No'],
  DBN: ['Yes', 'No'],
  MI: ['View', 'Manage', 'None'],
  TMP: ['View', 'Manage', 'None', 'Custom'],
  Default: ['View', 'Manage', 'None'],
};

export const VALUE_MAPPINGS = {
  Manage: 'Yes',
  View: 'No',
};
