// @ts-nocheck
/* eslint-disable no-unused-vars */
/* eslint-disable react/no-unstable-nested-components */
/* eslint-disable react/prop-types */
import React from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Typography,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { useTheme, alpha } from '@mui/material/styles';
import { ReactComponent as DropdownArrow } from '../../assets/images/DropdownArrow.svg';

const DropDown = React.memo(
  ({
    label,
    name,
    value,
    onChange,
    helperText,
    children,
    required = false,
    placeholder,
    disabled,
    size = 'medium',
    styles = {},
    ...props
  }) => {
    const theme = useTheme();
    return (
      <FormControl
        required={required}
        fullWidth
        sx={{
          '& .MuiFormLabel-root': {
            ...(helperText ? { color: 'error.main' } : {}),
          },
          ...styles,
        }}
        size={size}
      >
        <InputLabel id="demo-simple-select-label">{label}</InputLabel>
        <Select
          disabled={disabled}
          label={label}
          name={name}
          value={value || ''}
          onChange={onChange}
          IconComponent={(_props) => <DropdownArrow {..._props} />}
          error={Boolean(helperText)}
          sx={{
            '& > .MuiOutlinedInput-notchedOutline legend span': {
              ...(helperText ? { color: 'error.main' } : {}),
            },
            '& > svg path': {
              ...(helperText ? { stroke: theme.palette.error.main } : {}),
            },
          }}
          {...props}
          MenuProps={{
            PaperProps: {
              sx: {
                maxHeight: {
                  maxHeight: 'calc(100% - 60vh)',
                },
              },
            },
          }}
        >
          {children}
        </Select>
        <FormHelperText>
          <Typography color="error" variant="subtitle">
            {helperText}
          </Typography>
        </FormHelperText>
      </FormControl>
    );
  }
);
const DropdownItem = React.memo(({ children, ...props }) => (
  <MenuItem {...props}>
    <div
      style={{
        wordBreak: 'break-word',
        whiteSpace: 'wrap',
      }}
    >
      <Typography variant="body2">{children}</Typography>
    </div>
  </MenuItem>
));
export { DropdownItem, DropDown };
