// @ts-nocheck
// @ts-ignore
import React, { useEffect, useRef } from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
  Navigate,
  // Outlet,
  useLocation,
} from 'react-router-dom';
import { Box, IconButton, styled, CircularProgress } from '@mui/material';
import { useSelector } from 'react-redux';

import CloseIcon from '@mui/icons-material/Close';
import { SnackbarProvider as Snackbar } from 'notistack';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import useCurrentTheme from './Theme/useCurrentTheme';

import NotAuthorized from './components/Common/NotAuthorized';
import NotFound404 from './components/Common/NotFound404';
import FullscreenLoader from './components/Common/FullScreenLoader';
import { inializeAxios } from './utils/Axios';
import { Routes as routes } from './Routes';
import './App.css';

const Layout = React.lazy(() => import('./views/Layout/Layout'));
const Login = React.lazy(() => import('./views/Login/Login'));

// eslint-disable-next-line react/prop-types
const PrivateRoute = ({ children, isLoggedIn }) => {
  const location = useLocation();
  if (!isLoggedIn) {
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  return children;
};
const SnackbarProvider = styled(Snackbar)`
  &.SnackbarItem-contentRoot {
    width: 345px;
  }
  & .SnackbarItem-message {
    margin-right: 27px;
    word-break: break-word;
  }
  & .SnackbarItem-action {
    position: absolute;
    right: 0;
    top: 5px;
    margin-right: 5px;
    padding: 0;
  }
  &.SnackbarItem-variantSuccess {
    background: ${(p) => p.theme.palette.success.main};
  }
  &.SnackbarItem-variantError {
    background: ${(p) => p.theme.palette.error.main};
  }
  &.SnackbarItem-variantWarning {
    background: ${(p) => p.theme.palette.warning.main};
  }
  &.SnackbarItem-variantInfo {
    background: ${(p) => p.theme.palette.info.main};
  }
`;

function App() {
  const { isAuthenticate } = useSelector((state) => state.users);
  const theme = useCurrentTheme('light-theme');
  const notistackRef = useRef();
  const { users } = useSelector((state) => state);
  const { permissions } = users;

  useEffect(() => {
    // @ts-ignore
    window.enqueueSnackbar = notistackRef.current.enqueueSnackbar;
    window.closeSnackbar = notistackRef.current.closeSnackbar;
  }, []);

  /**
   * @function  onClickSnackBarClose
   * @description when click on cross button of toast dialog it will close the snack bar
   */
  const onClickSnackBarClose = (key) => {
    notistackRef.current.closeSnackbar(key);
  };
  // called here to use axios interceptor
  inializeAxios();

  const ChildRoutes = React.useMemo(() => {
    // Generates child routes based on provided route configurations and permissions.
    // Returns an array of React elements representing the child routes.
    return routes?.map(({ path, element, permission }) => {
      // Extracts required permissions for the current route.
      const requiredPermissions = permissions?.[permission];

      // Checks if the required permissions for the current route are 'None'.
      if (requiredPermissions === 'None') {
        // If permissions are 'None', renders a route with a special Permissions component.
        return <Route key={path} path={path} element={<NotAuthorized />} />;
      }

      // If permissions are not 'None', renders a route with the original element.
      return <Route key={path} path={path} element={element} />;
    });
  }, [permissions, routes]);

  return (
    <StyledEngineProvider injectFirst>
      <ThemeProvider theme={theme}>
        <SnackbarProvider
          ref={notistackRef}
          maxSnack={3}
          hideIconVariant
          preventDuplicate
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          autoHideDuration={5000}
          // eslint-disable-next-line react/no-unstable-nested-components
          action={(key) => (
            <IconButton onClick={() => onClickSnackBarClose(key)}>
              <CloseIcon color="#ffffff" />
            </IconButton>
          )}
        >
          <FullscreenLoader />
          <BrowserRouter>
            <React.Suspense
              fallback={
                <Box
                  display="flex"
                  flexGrow={1}
                  width="100%"
                  height="100vh"
                  alignItems="center"
                  flexDirection="row"
                >
                  <Box
                    alignItems="center"
                    display="flex"
                    marginRight="auto"
                    marginLeft="auto"
                  >
                    <CircularProgress />
                  </Box>
                </Box>
              }
            >
              <Routes>
                {/* <Route path="/login" element={<Login />} />
                 */}
                <Route path="/login" element={<Login />} />
                <Route
                  path="/"
                  element={
                    <PrivateRoute isLoggedIn={isAuthenticate}>
                      <Layout />
                    </PrivateRoute>
                  }
                >
                  {ChildRoutes}
                </Route>

                <Route path="*" name="Not Found" element={<NotFound404 />} />
              </Routes>
            </React.Suspense>
          </BrowserRouter>
        </SnackbarProvider>
      </ThemeProvider>
    </StyledEngineProvider>
  );
}

export default App;
