// @ts-nocheck
import { Box, Slide, Typography } from '@mui/material';
import React, { useState } from 'react';
import { ReactMuiTableColumnHeaderTextEllipsis } from 'solytics-frontend';
import PropTypes from 'prop-types';
import { useContextMenu } from 'react-contexify';

import { useTheme } from '@emotion/react';
import { ContextMenus } from '../Common/ContextMenus';
import ReactMuiTableListView from '../Common/ReactMuiTableListView';

const columns = [
  {
    heading: 'Role Id',
    accessor: 'role_id',
    Header: ReactMuiTableColumnHeaderTextEllipsis,
    width: 200,
  },
  {
    heading: 'Role Name',
    accessor: 'role_name',
    Header: ReactMuiTableColumnHeaderTextEllipsis,
    width: 200,
  },
  // {
  //   heading: 'Description',
  //   accessor: 'description',
  //   Header: ReactMuiTableColumnHeaderTextEllipsis,
  //   width: 200,
  // },
];
const RoleList = ({ roleList, tabValue, common, menus }) => {
  const theme = useTheme();
  const { show: showRowLevelMenus } = useContextMenu();
  const [selectedRow, setSelectedRow] = useState(null);
  return (
    <Box>
      {roleList?.length > 0 ? (
        <Slide
          key={tabValue}
          direction="left"
          timeout={1000}
          mountOnEnter
          in={true}
        >
          <Box
            display="flex"
            flexGrow={1}
            flexDirection="column"
            overflow="auto"
            height="calc(100vh - 300px)"
          >
            <ReactMuiTableListView
              data={roleList?.length > 0 ? roleList : []}
              columns={columns}
              getHeaderProps={() => ({
                style: {
                  display: 'flex',
                  alignItems: 'center',
                },
              })}
              getCellProps={(cell) => {
                return {
                  onContextMenu: (e) => {
                    setSelectedRow(cell?.row);
                    showRowLevelMenus({
                      id: `user-role-menus`,
                      event: e,
                      props: cell?.row,
                    });
                  },
                  style: {
                    backgroundColor:
                      cell?.row?.id === selectedRow?.id
                        ? theme.palette.secondary.main
                        : '',
                    position: 'relative',
                  },
                };
              }}
              enableRowSelection={true}
              pageCount={roleList?.length}
              enablePagination={true}
              initialPageSize={10}
              initialGlobalFilter={common?.userRoleListSearch}
              rowsPerPageOptions={[5, 10, 15]}
            />
            <ContextMenus
              menuId="user-role-menus"
              menus={menus}
              onMenuExit={() => {
                setSelectedRow(null);
              }}
            />
          </Box>
        </Slide>
      ) : (
        <Box
          display="flex"
          sx={{ height: 'calc(100vh  - 500px)' }}
          flexGrow={1}
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          <Typography variant="subtitle1">No data result found.</Typography>
        </Box>
      )}
    </Box>
  );
};

RoleList.propTypes = {
  roleList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
    .isRequired,
  tabValue: PropTypes.number.isRequired,
  common: PropTypes.oneOfType([PropTypes.object]).isRequired,
  menus: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default RoleList;
