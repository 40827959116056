import React from 'react';
import { IconButton, TextField } from '@mui/material';
import { styled } from '@mui/material/styles';
import InputAdornment from '@mui/material/InputAdornment';
import SearchIcon from '@mui/icons-material/Search';

const CustomTextField = styled(TextField)(({ theme }) => ({
  '& .MuiOutlinedInput-input': {
    padding: '10px !important',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: theme.palette.primary.main,
    },
  },
}));

// eslint-disable-next-line react/prop-types
const SearchTextField = ({ endIcon, ...props }) => {
  return (
    <CustomTextField
      InputProps={{
        startAdornment: (
          <InputAdornment position="start">
            <IconButton>
              <SearchIcon color="primary" />
            </IconButton>
          </InputAdornment>
        ),
        ...(endIcon && {
          endAdornment: (
            <InputAdornment position="start">{endIcon}</InputAdornment>
          ),
        }),
      }}
      {...props}
    />
  );
};

export default SearchTextField;
