// @ts-nocheck
import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Dialog,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
  styled,
} from '@mui/material';
import PropTypes from 'prop-types';
import { ReactMuiTable } from 'solytics-frontend';
import CloseIcon from '@mui/icons-material/Close';
import { useSelector } from 'react-redux';

import DialogTransition from '../Common/Transition/DialogTransition';
import NoDataFoundComponent from '../Common/NoDataFoundComponent';
import { DropDown, DropdownItem } from '../Common/DropDown';
import SwalToast from '../Common/SwalTost';
import { isValueEmpty } from '../../utils/Utils';
import { TabPanel } from '../Common/Tabs';
import theme from '../../Theme/base';

const MuiTabPanel = styled(TabPanel)(() => ({
  padding: '0px',
  paddingTop: '0px',
  width: '100%',
}));

const MuiTab = styled(Tab)(() => ({
  minHeight: '25px',
  marginBottom: '5px',
  display: 'flex',
  justifyContent: 'center',
  padding: '8px',
  '&.Mui-selected': {
    borderRadius: '3px',
    color: `${theme.palette.primary.main} !important`,
  },
  maxWidth: '200px',
  color: theme.palette.primary.main,
}));

const taskHistoryColumns = [
  {
    Header: 'TASK ID',
    accessor: 'task_id',
  },
  {
    Header: 'UNIQUE ID',
    accessor: 'entity_identifier',
  },
  {
    Header: 'Model NAME',
    accessor: 'entity_name',
  },
  {
    Header: 'ENTITY TYPE',
    accessor: 'entity_type',
  },
  {
    Header: 'CREATED AT',
    accessor: 'created_at',
  },
  {
    Header: 'ASSIGNED BY',
    accessor: 'assigned_by',
  },
  {
    Header: 'ASSIGNED TO',
    accessor: 'assigned_to',
  },
];

const TasksModal = ({
  open = false,
  handleClose = () => {},
  selectedGroupForEdit,
  getGroupTasks,
  userList,
  assignTask,
}) => {
  const [groupTasks, setGroupTasks] = useState({});
  const [selectedAssignTo, setSelectedAssignTo] = useState({});
  const [tabValue, setTabValue] = useState(0);

  const { userData } = useSelector((state) => state.users);

  /**
   * @function getGroupTasks
   * @description to get group tasks
   */
  const handleGetGroupTasks = () => {
    getGroupTasks({ groupid: selectedGroupForEdit?.id }).then((res) => {
      if (res) {
        setGroupTasks(res?.data);
      }
    });
  };

  const handleAssignToChange = (taskId, assignTo) => {
    const body = {
      task_id: taskId,
      user_id: assignTo,
      group_id: selectedGroupForEdit?.id,
    };
    assignTask(body).then((res) => {
      if (res) {
        SwalToast({
          icon: 'success',
          title: 'Task assigned successfully.',
        });
        setSelectedAssignTo((prevState) => ({
          ...prevState,
          [taskId]: assignTo,
        }));
      }
    });
  };

  /**
   * Handles the change of state for a specific event.
   * @param {Event} event - The event object representing the change.
   * @param {string} taskId - The task id.
   * @returns {void}
   */
  function handleAssignTo(event, taskId) {
    const assignTo = event.target.value;
    handleAssignToChange(taskId, assignTo);
  }

  const unAssignedTaskColumns = useMemo(() => {
    return [
      {
        Header: 'TASK ID',
        accessor: 'task_id',
      },
      {
        Header: 'UNIQUE ID',
        accessor: 'entity_identifier',
      },
      {
        Header: 'Model NAME',
        accessor: 'entity_name',
      },
      {
        Header: 'ENTITY TYPE',
        accessor: 'entity_type',
      },
      {
        Header: 'CREATED AT',
        accessor: 'created_at',
      },
      {
        Header: 'ASSIGN TO',
        accessor: 'assign_to',
        // eslint-disable-next-line react/no-unstable-nested-components, react/prop-types
        Cell: ({ row }) => {
          // eslint-disable-next-line react/prop-types
          const taskId = row?.original?.task_id;
          const users = selectedGroupForEdit?.users || [];
          const admins = selectedGroupForEdit?.admin_users || [];
          const isLoggedInUserAdminOfTheGroup = admins?.find(
            (admin) => admin?.username === userData?.username
          );

          const filteredUsers = isLoggedInUserAdminOfTheGroup
            ? users
            : users.filter((user) => user?.username === userData?.username);

          return (
            <DropDown
              label="Select assignee"
              value={selectedAssignTo[taskId] || ''}
              onChange={(event) => handleAssignTo(event, taskId)}
              size="small"
              disabled={!isValueEmpty(selectedAssignTo[taskId])}
            >
              {filteredUsers?.length > 0 ? (
                filteredUsers?.map((user) => {
                  return (
                    <DropdownItem key={user?.userId} value={user?.userId}>
                      {user?.username}
                    </DropdownItem>
                  );
                })
              ) : (
                <DropdownItem disabled>No assignee present.</DropdownItem>
              )}
            </DropDown>
          );
        },
      },
    ];
  }, [
    selectedAssignTo,
    handleAssignTo,
    userList,
    selectedGroupForEdit,
    userData?.username,
  ]);

  useEffect(() => {
    if (open) {
      handleGetGroupTasks();
    }
  }, [open, tabValue]);

  return (
    <Dialog
      maxWidth="lg"
      fullWidth={true}
      open={open}
      onClose={() => {
        handleClose();
      }}
      TransitionComponent={DialogTransition}
      sx={{
        '& .MuiPaper-root.MuiDialog-paper': {
          'padding-bottom': '42px',
        },
      }}
    >
      <Grid container xs={12}>
        <Grid
          item
          xs={12}
          sx={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Box
            display="flex"
            justifyContent="space-around"
            alignItems="center"
            flex={1}
          >
            <Typography variant="h3">Tasks</Typography>
          </Box>
          <Box>
            <IconButton onClick={handleClose}>
              <CloseIcon />
            </IconButton>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Tabs
            value={tabValue}
            onChange={(_event, newValue) => setTabValue(newValue)}
            variant="scrollable"
            sx={{
              border: 'none',
              '& .MuiTabs-indicator ': {
                // display: 'none',
              },
            }}
          >
            <MuiTab title="UnAssigned Tasks" label="UnAssigned Tasks" />
            <MuiTab title="Tasks History" label="Tasks History" />
          </Tabs>
        </Grid>
        <MuiTabPanel value={tabValue} index={0}>
          <Grid item xs={12}>
            <ReactMuiTable
              data={groupTasks?.UnassignedTask || []}
              initialHiddenColumns={[]}
              initialColumnOrder={[]}
              columns={unAssignedTaskColumns}
              rowLevelOnHoverOptions={() => null}
              getHeaderProps={() => ({
                style: {
                  display: 'flex',
                  alignItems: 'center',
                },
              })}
              getRowProps={() => ({
                style: {
                  position: 'relative',
                },
              })}
              getColumnProps={() => {
                return {
                  style: {
                    textAlign: 'left',
                    textOverflow: 'ellipsis',
                    overflow: ' hidden',
                    wordBreak: 'initial',
                    whiteSpace: 'nowrap',
                    paddingLeft: 10,
                  },
                };
              }}
              enableRowSelection={false}
              showToolbar={true}
              enableSearchBar={true}
              pageCount={groupTasks?.UnassignedTask?.length}
              enablePagination={true}
              initialPageSize={5}
              rowsPerPageOptions={
                isValueEmpty(groupTasks?.UnassignedTask) ? [] : [5, 10, 15]
              }
              skipPageReset={false}
              enableVirtualization={false}
              NoDataFoundComponent={NoDataFoundComponent}
            />
          </Grid>
        </MuiTabPanel>
        <MuiTabPanel value={tabValue} index={1}>
          <Grid item xs={12}>
            <ReactMuiTable
              data={groupTasks?.TaskHistory || []}
              initialHiddenColumns={[]}
              initialColumnOrder={[]}
              columns={taskHistoryColumns}
              rowLevelOnHoverOptions={() => null}
              getHeaderProps={() => ({
                style: {
                  display: 'flex',
                  alignItems: 'center',
                },
              })}
              getRowProps={() => ({
                style: {
                  position: 'relative',
                },
              })}
              getColumnProps={() => {
                return {
                  style: {
                    textAlign: 'left',
                    textOverflow: 'ellipsis',
                    overflow: ' hidden',
                    wordBreak: 'initial',
                    whiteSpace: 'nowrap',
                    paddingLeft: 10,
                  },
                };
              }}
              enableRowSelection={false}
              showToolbar={true}
              enableSearchBar={true}
              pageCount={groupTasks?.TaskHistory?.length}
              enablePagination={true}
              initialPageSize={5}
              rowsPerPageOptions={
                isValueEmpty(groupTasks?.UnassignedTask) ? [] : [5, 10, 15]
              }
              skipPageReset={false}
              enableVirtualization={false}
              NoDataFoundComponent={NoDataFoundComponent}
            />
          </Grid>
        </MuiTabPanel>
      </Grid>
    </Dialog>
  );
};

TasksModal.propTypes = {
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  getGroupTasks: PropTypes.func.isRequired,
  assignTask: PropTypes.func.isRequired,
  userList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
    .isRequired,
  selectedGroupForEdit: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.object])
  ).isRequired,
};

export default TasksModal;
