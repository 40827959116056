// @ts-nocheck
/* eslint-disable no-nested-ternary */
import React, { useCallback, useMemo, useState } from 'react';
import { Box, Stack, Breadcrumbs, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import {
  Link,
  useLocation,
  useNavigate,
  useSearchParams,
} from 'react-router-dom';
import * as _ from 'lodash';
import PrecisionManufacturingIcon from '@mui/icons-material/PrecisionManufacturing';

import { isValueEmpty } from '../../utils/Utils';
import { ReactComponent as ModelIcon } from '../../assets/images/ModelDetails/ModelLogo.svg';

import REGEX from '../../const/Regex';
import useCommon from '../../hooks/useCommon';
import ConfirmModal from './Modals/ConfirmModal';

const Breadcrumb = ({
  state: breadCrumbData,
  tabName = '',
  handleBreadcrumbClick = () => {},
  isDashboard,
}) => {
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [navigateToRoute, setNavigateToRoute] = useState(false);

  const { common, dispatch, setIsWorkflowCompiled } = useCommon();

  const navigate = useNavigate();

  /**
   * If workflow is not compile then show modal for confirmation
   *  @name handleModalOpen
   * @returns {void}
   */
  const handleModalOpen = () => {
    setIsConfirmModalOpen(true);
  };

  /**
   * Closes the confirmation modal by setting the state value.
   * @function
   * @name closeConfirmModal
   * @returns {void}
   * @example closeConfirmModal()
   */
  const closeConfirmModal = useCallback(() => {
    setIsConfirmModalOpen(false);
  }, [setIsConfirmModalOpen]);

  /**
   * Confirms the action, if user have compiled the workflow or not.
   *  @name confirm
   * @returns {void}
   */
  const confirm = useCallback(() => {
    dispatch(setIsWorkflowCompiled(true));
    closeConfirmModal();
    setTimeout(() => {
      navigate(navigateToRoute);
    }, 500);
  }, [dispatch, setIsWorkflowCompiled, navigateToRoute]);

  const location = useLocation();
  const paths = location.pathname.split('/').slice(1);
  const allPathsNames = _.map(paths, (item) => {
    if (item.match(REGEX.VALID_ENTITY_ID)) {
      return item;
    }
    return _.startCase(item);
  });
  const pathNames = allPathsNames.map((item) => {
    if (item === 'ModelAssociation') {
      return 'Model Artifacts';
    }
    if (item === 'ModelInventory') {
      return 'My Models';
    }
    if (item === 'Workflow') {
      return 'Workflow';
    }
    if (item === 'EditWorkflow') {
      return 'Edit Workflow';
    }
    return item;
  });
  const [searchParams] = useSearchParams();
  const templateName = searchParams.get('eid');
  const workFlowName = searchParams.get('workflowType');

  // This is to workflows compile warning feature
  const renderLinkTag = (to, value) => {
    if (to !== '/workflows/edit-workflow') {
      if (common?.isWorkflowCompiled) {
        return (
          <Link style={{ textDecoration: 'inherit' }} to={to}>
            <Typography variant="body2">{value}</Typography>
          </Link>
        );
      }

      // If workflow is not compiled then we will be restricting the navigation to other links
      return (
        <Typography
          variant="body2"
          sx={{
            cursor: 'pointer',
          }}
          onClick={() => {
            setNavigateToRoute(to);
            handleModalOpen();
          }}
        >
          {value}
        </Typography>
      );
    }
    return (
      <Link style={{ textDecoration: 'inherit' }} to={to}>
        <Typography variant="body2">{value}</Typography>
      </Link>
    );
  };

  const renderDashboardLink = useMemo(() => {
    if (common?.isWorkflowCompiled) {
      return (
        <Link
          style={{ textDecoration: 'none', cursor: 'pointer' }}
          underline="hover"
          key="1"
          color="inherit"
          to="/dashboard"
        >
          <Typography variant="body7">Dashboard</Typography>
        </Link>
      );
    }

    // If workflow is not compiled then we will be restricting the navigation to other links
    return (
      <Typography
        variant="body2"
        sx={{
          cursor: 'pointer',
        }}
        onClick={() => {
          setNavigateToRoute('/dashboard');
          handleModalOpen();
        }}
      >
        Dashboard
      </Typography>
    );
  }, [common?.isWorkflowCompiled, handleModalOpen, setNavigateToRoute]);

  return (
    <Box
      sx={{
        background: (_theme) => _theme.palette.secondary.light1,
        height: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      pl={2}
    >
      <Stack
        spacing={2}
        height="100%"
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Breadcrumbs
          separator={<NavigateNextIcon fontSize="small" />}
          aria-label="breadcrumb"
          sx={{
            height: '100%',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            '& .MuiBreadcrumbs-ol': {
              height: '100%',
            },
            '& .MuiBreadcrumbs-li': {
              '& span': {
                paddingLeft: '8px',
                paddingRight: '8px',
              },

              height: '100%',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            },
          }}
        >
          {!pathNames?.includes('Dashboard') ? renderDashboardLink : null}
          {breadCrumbData?.length > 0
            ? breadCrumbData?.map((path, i) => {
                return i === Number(breadCrumbData?.length) - 1 ? (
                  <Box
                    sx={{
                      background: (_theme) =>
                        _theme?.palette.secondary.contrastText,
                      height: '100%',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                    }}
                  >
                    {!_.isEmpty(path?.type) ? (
                      path?.type === 'ModelInventory' ? (
                        <Box
                          title="Model"
                          component="span"
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <ModelIcon
                            color="primary"
                            style={{
                              width: '18px',
                              height: '18px',
                            }}
                          />
                        </Box>
                      ) : (
                        <Box title="Model Artifact" component="span">
                          <PrecisionManufacturingIcon
                            color="primary"
                            style={{
                              width: '18px',
                              height: '18px',
                              paddingTop: '3px',
                            }}
                          />
                        </Box>
                      )
                    ) : null}
                    <Typography key={path?.path} variant="body2">
                      {path?.label}
                    </Typography>
                  </Box>
                ) : (
                  <Box
                    key={path?.path}
                    sx={{ display: 'flex', justifyContent: 'center' }}
                  >
                    {!_.isEmpty(path?.type) ? (
                      path?.type === 'ModelInventory' ? (
                        <Box
                          title="Model"
                          component="span"
                          sx={{
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                          }}
                        >
                          <ModelIcon
                            color="primary"
                            style={{
                              width: '18px',
                              height: '18px',
                            }}
                          />
                        </Box>
                      ) : (
                        <Box title="Model Artifact" component="span">
                          <PrecisionManufacturingIcon
                            color="primary"
                            style={{
                              width: '18px',
                              height: '18px',
                              paddingTop: '3px',
                            }}
                          />
                        </Box>
                      )
                    ) : null}
                    <Link
                      style={{ textDecoration: 'inherit', cursor: 'pointer' }}
                      to={path?.path}
                      state={breadCrumbData.slice(0, i + 1)}
                    >
                      <Typography variant="body7">
                        {path?.label === 'ModelInventory'
                          ? 'My Models'
                          : path?.label === 'ModelAssociation'
                          ? 'Model Artifacts'
                          : path?.label}
                      </Typography>
                    </Link>
                  </Box>
                );
              })
            : pathNames.map((value, index) => {
                const to = `/${paths.slice(0, index + 1).join('/')}`;
                return index === paths.length - 1 ? (
                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      ...((isValueEmpty(tabName) ||
                        isValueEmpty(templateName) ||
                        isValueEmpty(templateName) ||
                        isValueEmpty(workFlowName)) && {
                        height: '100%',
                        paddingTop: '14px',
                        paddingBottom: '14px',
                      }),
                      background: (_theme) =>
                        !isValueEmpty(tabName) ||
                        !isValueEmpty(templateName) ||
                        !isValueEmpty(templateName) ||
                        !isValueEmpty(workFlowName)
                          ? _theme.palette.secondary.light1
                          : _theme?.palette.secondary.contrastText,
                    }}
                  >
                    {breadCrumbData?.type === 'ModelInventory' ? (
                      <Box
                        title="Model"
                        component="span"
                        sx={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                        }}
                      >
                        <ModelIcon
                          color="primary"
                          style={{
                            width: '18px',
                            height: '18px',
                          }}
                        />
                      </Box>
                    ) : breadCrumbData?.type === 'ModelAssociation' ? (
                      <Box title="Association" component="span">
                        <PrecisionManufacturingIcon
                          color="primary"
                          style={{
                            width: '18px',
                            height: '18px',
                            paddingTop: '3px',
                          }}
                        />
                      </Box>
                    ) : null}
                    {isDashboard ? (
                      <Typography
                        variant="body2"
                        onClick={handleBreadcrumbClick}
                      >
                        {value}
                      </Typography>
                    ) : (
                      <Typography key={to} variant="body2">
                        {breadCrumbData?.uniqueId || value}
                      </Typography>
                    )}
                  </Box>
                ) : (
                  <Box>{renderLinkTag(to, value)}</Box>
                );
              })}
          {workFlowName ? (
            <Typography
              variant="body2"
              sx={{
                background: (_theme) => _theme?.palette.secondary.contrastText,
                height: '100%',
                paddingTop: '14px',
                paddingBottom: '14px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {workFlowName}
            </Typography>
          ) : null}
          {templateName ? (
            <Typography
              variant="body2"
              sx={{
                background: (_theme) => _theme?.palette.secondary.contrastText,
                height: '100%',
                paddingTop: '14px',
                paddingBottom: '14px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {templateName}
            </Typography>
          ) : null}
          {tabName ? (
            <Typography
              variant="body2"
              sx={{
                background: (_theme) => _theme?.palette.secondary.contrastText,
                height: '100%',
                paddingTop: '14px',
                paddingBottom: '14px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              {tabName}
            </Typography>
          ) : null}
        </Breadcrumbs>
      </Stack>
      <ConfirmModal
        open={isConfirmModalOpen}
        handleClose={closeConfirmModal}
        confirm={confirm}
        alertLabelText={
          <Typography>{`You have unsaved changes. Do you really want to leave this page
        without saving?`}</Typography>
        }
        confirmButtonLabel="Yes"
        cancelButtonLabel="No"
      />
    </Box>
  );
};
export default Breadcrumb;
Breadcrumb.propTypes = {
  state: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])).isRequired,
  tabName: PropTypes.string.isRequired,
  handleBreadcrumbClick: PropTypes.func.isRequired,
  isDashboard: PropTypes.bool.isRequired,
};
