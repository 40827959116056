/* eslint-disable no-unused-vars */
// @ts-nocheck
import { useState, useEffect } from 'react';
import {
  Box,
  Dialog,
  Grid,
  IconButton,
  Typography,
  TextField,
  Stack,
  Button,
} from '@mui/material';
import PropTypes from 'prop-types';
import CloseIcon from '@mui/icons-material/Close';
import { capitalize, cloneDeep, isEmpty } from 'lodash';
import { useFormik } from 'formik';
import { string, object } from 'yup';
import MuiAccordion from '@mui/material/Accordion';
import MuiAccordionSummary from '@mui/material/AccordionSummary';
import MuiAccordionDetails from '@mui/material/AccordionDetails';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { styled } from '@mui/material/styles';

import { isValueEmpty } from '../../utils/Utils';
import DialogTransition from '../Common/Transition/DialogTransition';
import REGEX from '../../const/Regex';
import { INVALID_REGEX_MESSAGE } from '../../const/CommonConst';
import ROLE_PERMISSION_TREE from '../../const/RolesPermission';

const Accordion = styled((props) => (
  <MuiAccordion disableGutters elevation={0} square {...props} />
))(() => ({
  '&:not(:last-child)': {
    borderBottom: 0,
  },
  '&:before': {
    display: 'none',
  },
}));

const AccordionSummary = styled((props) => <MuiAccordionSummary {...props} />)(
  ({ theme }) => ({
    backgroundColor: theme.palette.other.white,
    flexDirection: 'row-reverse',
    '& .MuiAccordionSummary-expandIconWrapper.Mui-expanded': {
      transform: 'rotate(90deg)',
    },
    '& .MuiAccordionSummary-content': {
      marginLeft: theme.spacing(1),
    },
  })
);

const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.other.white,
}));

/**
  @function CreateEditRoleModal
  */
const STEP_TITLES_CREATE_MODE = {
  first: 'Create Role',
  second: 'Manage Permissions',
};
const CreateEditRole = ({
  open = false,
  handleClose,
  mode,
  createUserRole,
  rolePermission,
  selectedRole,
  updateUserRole,
}) => {
  const [step, setStep] = useState('first');
  const [moduleSubModules, setModuleSubModules] = useState(cloneDeep({}));

  const formValidationSchema = object({
    roleName: string()
      .max(70, 'max characters should be 70')
      .required('Required')
      .matches(REGEX.SPECIAL_CHARACTERS_NOT_ALLOWED, INVALID_REGEX_MESSAGE)
      .matches(REGEX.CANNOT_START_WITH_NUMBERS, INVALID_REGEX_MESSAGE),
    // roleDescription: string()
    //   .max(254, 'max characters should be 254')
    //   .matches(
    //     REGEX.WHOLE_STRING_CANNOT_BE_SPECIAL_CHARACTERS,
    //     INVALID_REGEX_MESSAGE
    //   ),
  });

  const formikForm = useFormik({
    initialValues: {
      roleName: '',
      roleDescription: '',
    },
    validationSchema: formValidationSchema,
    onSubmit: (values) => {
      // eslint-disable-next-line no-use-before-define
      const actionsJson = extractPermissions(moduleSubModules);
      Object.keys(actionsJson).forEach((key) => {
        if (!key.includes('_')) {
          delete actionsJson[key];
        }
      });
      const body = {
        actions_json: actionsJson,
        role_name: values?.roleName?.trim(),
        // description: values?.roleDescription,
      };
      if (mode === 'create') {
        createUserRole(body);
      }
      if (mode === 'edit') {
        updateUserRole({ ...body, role_id: selectedRole?.role_id });
      }
      handleClose();
    },
  });

  // Function to recursively process the object and extract the permissions
  function extractPermissions(obj, parentKey = '') {
    let result = {};

    // Iterate over the object keys
    Object.keys(obj).forEach((key) => {
      const objKey = obj[key].key;

      const currentKey = parentKey ? `${parentKey}_${objKey}` : objKey;
      const value = obj[key];

      // Check if the current key represents a submodule
      if (Object.hasOwn(value, 'subModules')) {
        const subModulePermissions = extractPermissions(
          value.subModules,
          currentKey
        );
        result = { ...result, ...subModulePermissions };
      }

      // Check if the current key has a permission value
      if (Object.hasOwn(value, 'permission')) {
        result[currentKey] = value.permission;
      }
    });

    return result;
  }

  function addPermissionKey(obj) {
    Object.keys(obj).forEach((key) => {
      const property = obj[key];
      property.permission = 'No';

      if (Object.hasOwn(property, 'subModules')) {
        addPermissionKey(property?.subModules);
      }
    });

    return obj;
  }

  const transformedPermissionsObject = {};

  /**
   * @function transformPermissionJsonObject
   * @description Transforms the permissionJson object of a selectedGroupItem and populates the transformedPermissionsObject.
   * @param {Object} rolePermission - The selected role item containing permissionJson.
   */

  function transformPermissionJsonObject(permission) {
    Object.keys(permission).forEach((key) => {
      if (key.includes('_')) {
        const parts = key.split('_');
        const newKey = parts[parts.length - 1];

        transformedPermissionsObject[newKey] = permission?.[key];
      } else {
        const parts = key.split(' ');
        const newKey = parts[0];
        transformedPermissionsObject[newKey] = permission?.[key];
      }
    });
  }

  /**
   * @function iterateNestedObject
   * @description Recursively iterates through an object and updates the 'permission' property based on transformedPermissionsObject.
   * @param {Object} obj - The object to iterate and update permissions.
   */

  function iterateNestedObject(obj) {
    // Iterating obj to change the permissions from permissionJson obj

    if (typeof obj === 'object') {
      Object.keys(obj).forEach((key) => {
        if (key === 'permission') {
          // If obj.key is not present in permissionJson then we are adding default as No.

          // eslint-disable-next-line no-nested-ternary
          const updatedPermission = transformedPermissionsObject[obj?.key]
            ? transformedPermissionsObject[obj?.key]
            : 'No';

          // eslint-disable-next-line no-param-reassign
          obj[key] = updatedPermission; // Update the permission property
        }

        iterateNestedObject(obj[key]);
      });
    }
  }

  const calculateModulePermission = (module) => {
    const moduleCopy = cloneDeep(module);

    // Extracting submodule permissions as an array
    const submodulePermissions = Object.values(moduleCopy?.subModules).map(
      (subModule) => subModule?.permission
    );

    // Helper function to check if all permissions match the target permissions
    const allPermissionsEqual = (permissions, targetPermissions) =>
      permissions.every((permission) => targetPermissions.includes(permission));

    // Using a switch statement to handle different permission scenarios
    switch (true) {
      case allPermissionsEqual(submodulePermissions, ['No']):
        return 'No'; // Return 'No' if all permissions are  'No'
      case allPermissionsEqual(submodulePermissions, ['Yes']):
        return 'Yes'; // Return 'Yes' if all permissions are 'Yes'
      default:
        return 'Custom'; // Return 'Custom' for any other combination of permissions
    }
  };

  function updateModulePermissions(obj) {
    let result = {};

    if (typeof obj === 'object') {
      Object.keys(obj).forEach((key) => {
        if (typeof obj[key] === 'object') {
          // eslint-disable-next-line no-param-reassign
          obj[key] = updateModulePermissions(obj[key]);
        }
      });

      if (obj.subModules) {
        // eslint-disable-next-line no-param-reassign
        obj.permission = calculateModulePermission(obj);
      }

      result = obj;
    }

    return result;
  }

  useEffect(() => {
    switch (mode) {
      case 'create': {
        const initialDefaultPermissionsModules = addPermissionKey(
          cloneDeep(ROLE_PERMISSION_TREE)
        );

        setModuleSubModules(cloneDeep(initialDefaultPermissionsModules));
        break;
      }
      case 'edit': {
        const initialDefaultPermissionsModulesEdit = addPermissionKey(
          cloneDeep(ROLE_PERMISSION_TREE)
        );
        if (selectedRole) {
          formikForm.setValues({
            roleName: selectedRole?.role_name,
            // roleDescription: selectedRole?.role_description,
          });
        }
        if (rolePermission) {
          // Transforming the permission object received from backend.
          transformPermissionJsonObject(rolePermission);

          // Iterate through every nested module and subModules and updating the permission according to backend permission json
          iterateNestedObject(initialDefaultPermissionsModulesEdit);

          let result = {};
          // Iterate through every nested module and subModules from bottom to top and updating the all modules permissions according to newly added objects (i.e templates or modules)
          result = updateModulePermissions(
            cloneDeep(initialDefaultPermissionsModulesEdit)
          );

          setModuleSubModules({ ...cloneDeep(result) });
        }

        break;
      }
      default:
        break;
    }
  }, [mode, rolePermission]);

  /**
   * Handles the click event for a specific action.
   * Validates the form and updates the step or submits the form accordingly.
   * @function
   * @name handleClick
   * @returns {void}
   */
  const handleClick = () => {
    formikForm.validateForm().then((validate) => {
      if (
        formikForm.values?.roleName.length <= 150 &&
        Object.keys(validate).length === 0
      ) {
        setStep('second');
      } else {
        formikForm.handleSubmit();
      }
    });
  };

  const handlePermissionChange = (moduleKey, subModuleKey, option) => {
    const updatedModuleSubModules = cloneDeep(moduleSubModules);

    const module = updatedModuleSubModules[moduleKey];
    const subModule = subModuleKey ? module?.subModules?.[subModuleKey] : null;

    switch (true) {
      case Boolean(subModule):
        subModule.permission = option;
        // Updating module permission based on subModules permissions.
        module.permission = calculateModulePermission(module);
        break;

      case Boolean(module):
        module.permission = option;
        // Updating Submodules permission when we change module permission

        Object.values(module.subModules).forEach((subModuleItem) => {
          // eslint-disable-next-line no-param-reassign
          subModuleItem.permission = option;
        });

        break;

      default:
        break;
    }

    setModuleSubModules(cloneDeep(updatedModuleSubModules));
  };

  const renderPermissions = (permission, moduleKey, subModuleKey) => {
    const options = ['Yes', 'No'];
    if (isValueEmpty(subModuleKey)) {
      options.push('Custom');
    }
    return (
      <Box ml={2}>
        <Stack direction="row" spacing={0.5}>
          {options.map((option, index) => (
            <div key={option}>
              <Typography
                onClick={(e) => {
                  // Custom permission button should be non clickable
                  e.stopPropagation();
                  if (option !== 'Custom') {
                    handlePermissionChange(moduleKey, subModuleKey, option);
                  }
                }}
                sx={{
                  color:
                    permission === option
                      ? (theme) => theme.palette.primary.dark
                      : (theme) => theme.palette.other.white2,
                  cursor: 'pointer',
                }}
              >
                {option}
              </Typography>
              {/* This condition is for not showing slash(/) for last element. */}
              {options?.length !== index + 1 && (
                <Typography
                  sx={{
                    color: (theme) => theme.palette.other.white2,
                  }}
                >
                  /
                </Typography>
              )}
            </div>
          ))}
        </Stack>
      </Box>
    );
  };

  return (
    <Box>
      <Dialog
        maxWidth={step === 'first' ? 'xs' : 'xs'}
        fullWidth={true}
        open={open}
        onClose={() => {
          handleClose();
        }}
        TransitionComponent={DialogTransition}
        sx={{
          '& .MuiPaper-root.MuiDialog-paper': {
            'padding-bottom': '42px',
          },
        }}
      >
        <Grid container xs={12}>
          <Grid item xs={12}>
            <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
              <IconButton
                onClick={() => {
                  handleClose();
                }}
              >
                <CloseIcon />
              </IconButton>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Box
              display="flex"
              justifyContent="space-around"
              alignItems="center"
              mb={2}
            >
              <Typography variant="h3">
                {step === 'first'
                  ? `${capitalize(mode)} user role`
                  : `${STEP_TITLES_CREATE_MODE[step]}`}
              </Typography>
            </Box>
          </Grid>
          <Grid
            container
            rowSpacing={5}
            xs={12}
            display={step === 'first' ? 'block' : 'none'}
          >
            <Grid
              item
              container
              xs={12}
              display="flex"
              justifyContent="space-around"
              alignItems="center"
              spacing={2}
            >
              <Grid item xs={12}>
                <TextField
                  required
                  name="roleName"
                  value={formikForm?.values?.roleName}
                  {...formikForm.getFieldProps('roleName')}
                  placeholder="Enter role name."
                  label="Role name"
                  helperText={
                    formikForm?.errors?.roleName &&
                    formikForm?.touched?.roleName
                      ? formikForm?.errors?.roleName
                      : null
                  }
                  error={
                    Boolean(formikForm?.errors?.roleName) &&
                    formikForm?.touched?.roleName
                  }
                />
              </Grid>
              <Grid item xs={12} sx={{ display: 'none' }}>
                <TextField
                  required
                  name="roleDescription"
                  value={formikForm?.values?.roleDescription}
                  {...formikForm.getFieldProps('roleDescription')}
                  placeholder="Enter role description."
                  label="Role description"
                  helperText={
                    formikForm?.errors?.roleDescription &&
                    formikForm?.touched?.roleDescription
                      ? formikForm?.errors?.roleDescription
                      : null
                  }
                  error={
                    Boolean(formikForm?.errors?.roleDescription) &&
                    formikForm?.touched?.roleDescription
                  }
                />
              </Grid>
              <Grid item xs={12}>
                <Stack
                  direction="row"
                  spacing={3}
                  justifyContent="center"
                  mt={3}
                >
                  <Button
                    onClick={() => {
                      formikForm.handleReset();
                    }}
                  >
                    RESET
                  </Button>
                  <Button onClick={handleClick} variant="contained">
                    NEXT
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </Grid>
          {/* Second Step */}
          <Grid container display={step === 'second' ? 'block' : 'none'}>
            <Grid xs={12} item textAlign="center">
              <Box mb={2}>
                <Typography textAlign="center">
                  Please select permissions.
                </Typography>
              </Box>
            </Grid>
            <Grid item xs={12}>
              <Box p={4}>
                <Grid container spacing={2}>
                  {Object.values(moduleSubModules).map((module) => (
                    <Grid
                      item
                      container
                      xs={12}
                      spacing={2}
                      key={module.objKey}
                    >
                      <Accordion key={module.objKey}>
                        <AccordionSummary
                          expandIcon={<KeyboardArrowRightIcon />}
                          aria-controls={module.objKey}
                        >
                          <Box display="flex" alignItems="center">
                            <Typography>{module.label}</Typography>
                          </Box>
                          {renderPermissions(
                            module?.permission,
                            module?.objKey,
                            null
                          )}
                        </AccordionSummary>
                        <AccordionDetails>
                          <Box ml={4}>
                            {Object.values(module?.subModules).map(
                              (subModule) => {
                                return (
                                  <Box key={subModule?.objKey} display="flex">
                                    <Typography
                                      mr={2}
                                      sx={{
                                        color: (theme) =>
                                          theme.palette.other.gray2,
                                      }}
                                    >
                                      {subModule?.label} :
                                    </Typography>
                                    {renderPermissions(
                                      subModule?.permission,
                                      module?.objKey,
                                      subModule?.objKey
                                    )}
                                  </Box>
                                );
                              }
                            )}
                          </Box>
                        </AccordionDetails>
                      </Accordion>
                    </Grid>
                  ))}
                </Grid>
              </Box>
            </Grid>
            <Stack direction="row" spacing={3} justifyContent="center" mt={3}>
              <Button
                onClick={() => {
                  setStep('first');
                }}
              >
                BACK
              </Button>
              <Button
                color="primary"
                variant="contained"
                onClick={formikForm.handleSubmit}
              >
                {mode === 'create' ? 'CREATE ROLE' : 'UPDATE ROLE'}
              </Button>
            </Stack>
          </Grid>
        </Grid>
      </Dialog>
    </Box>
  );
};

CreateEditRole.propTypes = {
  handleClose: PropTypes.func.isRequired,
  createUserRole: PropTypes.func.isRequired,
  updateUserRole: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  selectedRole: PropTypes.oneOfType([PropTypes.object]).isRequired,
  rolePermission: PropTypes.oneOfType([PropTypes.object]).isRequired,
  mode: PropTypes.oneOf(['create', 'edit']).isRequired,
};
export default CreateEditRole;
