// @ts-nocheck
import {
  Avatar,
  AvatarGroup,
  Box,
  Grid,
  Slide,
  Typography,
} from '@mui/material';
import React, { useState } from 'react';
import { ReactMuiTableColumnHeaderTextEllipsis } from 'solytics-frontend';
import PropTypes from 'prop-types';
import { useContextMenu } from 'react-contexify';

import { useTheme } from '@emotion/react';
import moment from 'moment';
import { ContextMenus } from '../Common/ContextMenus';
import ReactMuiTableListView from '../Common/ReactMuiTableListView';

const AvararCellUsers = ({ row }) => {
  return (
    <Grid item xs={3} display="flex" flexDirection="row">
      {row?.original?.users?.length > 0 ? (
        <AvatarGroup max={3}>
          {row?.original?.users?.map((user) => (
            <Avatar
              key={user?.username}
              alt={user?.username}
              sx={{
                color: 'text.primary',
                ml: '-15px',
                backgroundColor: 'primary.main',
              }}
            >
              {user?.username?.charAt(0)}
            </Avatar>
          ))}
        </AvatarGroup>
      ) : (
        <Typography variant="subtitle1">No user</Typography>
      )}
    </Grid>
  );
};

AvararCellUsers.propTypes = {
  row: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

const AvararCellAdmins = ({ row }) => {
  return (
    <Grid item xs={3} display="flex" flexDirection="row">
      {row?.original?.admin_users?.length > 0 ? (
        <AvatarGroup max={3}>
          {row?.original?.admin_users?.map((user) => (
            <Avatar
              key={user?.username}
              alt={user?.username}
              sx={{
                color: 'text.primary',
                ml: '-15px',
                backgroundColor: 'primary.main',
              }}
            >
              {user?.username?.charAt(0)}
            </Avatar>
          ))}
        </AvatarGroup>
      ) : (
        <Typography variant="subtitle1">No user</Typography>
      )}
    </Grid>
  );
};

AvararCellAdmins.propTypes = {
  row: PropTypes.oneOfType([PropTypes.object]).isRequired,
};
const columns = [
  {
    heading: 'GROUP NAME',
    accessor: 'groupName',
    Header: ReactMuiTableColumnHeaderTextEllipsis,
    width: 200,
  },
  {
    heading: 'NUMBER OF USERS',
    accessor: 'users',
    Header: ReactMuiTableColumnHeaderTextEllipsis,
    width: 200,
    Cell: AvararCellUsers,
  },
  {
    heading: 'CREATED DATE',
    id: 'createdDate',
    accessor: (row) => {
      return moment(new Date(row?.createdDate)).format('MM/DD/YYYY');
    },
    Header: ReactMuiTableColumnHeaderTextEllipsis,
    width: 200,
  },
  {
    heading: 'DESCRIPTION',
    accessor: 'description',
    Header: ReactMuiTableColumnHeaderTextEllipsis,
    width: 200,
  },
  {
    heading: 'ADMINS',
    accessor: 'admin_users',
    Header: ReactMuiTableColumnHeaderTextEllipsis,
    width: 200,
    Cell: AvararCellAdmins,
  },
];
const GroupList = ({
  groupList,
  tabValue,
  menus,
  skipPageReset,
  common,
  onTableStateChange,
}) => {
  const theme = useTheme();
  const { show: showRowLevelMenus } = useContextMenu();
  const [selectedRow, setSelectedRow] = useState(null);
  return (
    <Box>
      {groupList?.length > 0 ? (
        <Slide
          key={tabValue}
          direction="left"
          timeout={1000}
          mountOnEnter
          in={true}
        >
          <Box
            display="flex"
            flexGrow={1}
            flexDirection="column"
            overflow="auto"
            height="calc(100vh - 300px)"
          >
            <ReactMuiTableListView
              data={groupList?.length > 0 ? groupList : []}
              columns={columns}
              getHeaderProps={() => ({
                style: {
                  display: 'flex',
                  alignItems: 'center',
                },
              })}
              getCellProps={(cell) => {
                return {
                  onContextMenu: (e) => {
                    setSelectedRow(cell?.row);
                    showRowLevelMenus({
                      id: `model-inventory-table-menus-group`,
                      event: e,
                      props: cell?.row,
                    });
                  },
                  style: {
                    backgroundColor:
                      cell?.row?.id === selectedRow?.id
                        ? theme.palette.secondary.main
                        : '',
                    position: 'relative',
                  },
                };
              }}
              enableRowSelection={true}
              pageCount={groupList?.length}
              enablePagination={true}
              initialPageSize={10}
              initialGlobalFilter={common?.groupListSearch}
              rowsPerPageOptions={[5, 10, 15]}
              skipPageReset={skipPageReset}
              onTableStateChange={onTableStateChange}
            />
            <ContextMenus
              menuId="model-inventory-table-menus-group"
              menus={menus}
              onMenuExit={() => {
                setSelectedRow(null);
              }}
            />
          </Box>
        </Slide>
      ) : (
        <Box
          display="flex"
          sx={{ height: 'calc(100vh  - 500px)' }}
          flexGrow={1}
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          <Typography variant="subtitle1">No data result found.</Typography>
        </Box>
      )}
    </Box>
  );
};

export default GroupList;

GroupList.propTypes = {
  groupList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
    .isRequired,
  tabValue: PropTypes.number.isRequired,
  skipPageReset: PropTypes.bool.isRequired,
  common: PropTypes.oneOfType([PropTypes.object]).isRequired,
  menus: PropTypes.oneOfType([PropTypes.object]).isRequired,
  onTableStateChange: PropTypes.func.isRequired,
};
