import React from 'react';
import { useNavigate } from 'react-router-dom';
import { Grid, Typography, Button, Box } from '@mui/material';
import { ReactComponent as NimbusLogo } from '../../assets/Icons/MRMVaultColored.svg';

const NotFound404 = () => {
  const history = useNavigate();
  const unselectableStyles = {
    userSelect: 'none',
    WebkitTouchCallout: 'none',
    KhtmlUserSelect: 'none',
    MozUserSelect: 'none',
    msUserSelect: 'none',
  };
  return (
    <Grid
      container
      justifyContent="center"
      alignItems="center"
      sx={{ height: '100vh', flexDirection: 'column' }}
    >
      <Grid item>
        <Box p={1} marginBottom={2}>
          <NimbusLogo height="100px" width="170px" />
        </Box>
      </Grid>
      <Grid item sx={{ textAlign: 'center' }}>
        <Typography
          variant="h1"
          color="primary"
          fontWeight="bold"
          sx={{ fontSize: 400, ...unselectableStyles }}
        >
          404
        </Typography>
        <Typography
          variant="h5"
          color="primary"
          fontWeight="bold"
          sx={{ fontSize: 20, ...unselectableStyles }}
        >
          Not Found
        </Typography>
      </Grid>
      <Grid item mt={5}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => history({ pathname: '/' })}
        >
          Go Back Home
        </Button>
      </Grid>
    </Grid>
  );
};

export default NotFound404;
