const API_ENDPOINTS = {
  HOSTS_NEEDS_TOKEN: [process.env.REACT_APP_HOSTS_NEEDS_TOKEN],
  MODULE_BASE_URL: {
    AUTH: process.env.REACT_APP_VAULT_BASE_URL,
  },
  AUTH: {
    LOGIN_USER: '/vault/auth/login/',
    GET_ALL_USER: '/vault/auth/getAlluser',
    CREATE_USER: '/vault/auth/register/',
    GET_ALL_ROLES: '/vault/roles',
    USER_PERMISSION: '/vault/auth/getUserPermission',
    UPDATE_USER: '/vault/profile/update/',
    SUSPEND_USER: '/vault/auth/suspend',
    REFRESH_TOKEN: '/vault/auth/token/refresh/',
    FORGET_PASSWORD: '/vault/auth/request_reset_email/',
    RESET_PASSWORD: '/vault/auth/password_reset_complete/',
    USER_SETTING: '/vault/auth/user-settings',
    IS_LOGGED_IN_USER_ADMIN_OF_GROUP: '/vault/auth/isAdmin',
  },
  NOTIFICATIONS: {
    GET_NOTIFICATION: '/vault/notification/getAll',
    CLEAR_NOTIFICATION: '/vault/notification/clearAll',
    READ_UNREAD: '/vault/notification/Marksingleread',
    MARK_ALL_READ: '/vault/notification/MarkAsRead',
  },
  CE: {
    CREATE_ENTITY: '/vault/custom-entity',
    GET_ALL_ENTITY: '/vault/custom-entity',
    GET_SINGLE_ENTITY: '/vault/custom-entity',
    CREATE_SECTION: '/vault/custom-entity/section',
    GET_SECTIONS_DETAILS: '/vault/custom-entity/section',
    REMOVE_SECTION: '/vault/custom-entity/section',
    CREATE_ATTRIBUTES: '/vault/custom-entity/attribute',
    GET_ATTRIBUTES: '/vault/get-attribute',
    CREATE_ATTRIBUTE: '/vault/custom-entity/attribute',
    REMOVE_ATTRIBUTE: '/vault/custom-entity/attribute',
    ATTACH_WORKFLOW: '/vault/custom-entity/workflow',
    DELETE_TEMPLATE: '/vault/custom-entity',
    REORDER_TEMPLATE: '/vault/orderRearrange',
    GET_ALL_CUSTOM_ENTITY: '/vault/custom-entity/getAll',
    GET_MODEL_DETAILS_OF_WORKFLOW_CHANGES:
      '/vault/custom-entity/updateworkflow',
    TRANSITION_ALL_MODELS: '/vault/custom-entity/updateworkflow',
    GET_WORKFLOW_ROLES: '/vault/workflow/getAllRoles',
    CHECK_TEMPLATE: '/vault/custom-entity/checkTemplate',
  },
  MI: {
    READ_ALL_MODEL_ENTITY: '/vault/model-entity/readAll',
    CREATE_MODEL_ENTITY_FIRST_STEP: '/vault/model-entity/createModel',
    CREATE_MODEL_ENTITY_SECOND_STEP:
      '/vault/model-entity/updateModel/attribute',
    CREATE_MODEL_ENTITY_THIRD_STEP:
      '/vault/model-entity/createModel/addModelAssociaton',
    CREATE_MODEL_ENTITY_FORTH_STEP:
      '/vault/model-entity/createModel/addModelTeam',
    ATTRIBUTE_DETAILS: '/vault/model-entity/read',
    TEAM_DETAILS: '/vault/model-entity/modelTeam',
    ASSOCIATION_DETAILS: '/vault/model-entity/read/modelAssociation',
    ADD_SINGLE_TEAM_MEMBER: '/vault/model-entity/createModel/addSingleTeam',
    DELETE_ENTITY: '/vault/model-entity/removeModel',
    MODEL_APPROVE: '/vault/workflow/NextStage',
    SAVE_FILTER: '/vault/savesearch',
    REMOVE_TEAM_MEMBER: '/vault/model-entity/removeModel/teamMember',
    REMOVE_MODEL_ASSOCIATION: '/vault/model-entity/removeModel/association',
    GET_SINGLE_ENTITY: '/vault/model-entity/readSingle',
    HISTORY: '/vault/model-entity/modelHistory',
    GET_LOG_ACTIONS: '/vault/model-entity/getLogactions',
    GET_LOG_ACTIONS_ATTRIBUTES: '/vault/model-entity/getLogattributes',
    GET_NOTES: '/vault/model-entity/modelNotes',
    GET_REPLIES: '/vault/replies',
    GET_TRANSITION_STATES: '/vault/transitionstates',
    GET_LIFECYCLE_NOTES: '/vault/lifecycle-note',
    NOTES: '/vault/notes',
    ADD_NOTES: '/vault/model-entity/updateModel/addNote',
    DELETE_FILTER: '/vault/savesearch',
    EDIT_FILTER: '/vault/search',
    GET_ALERTS: '/vault/alerts',
    UPDATE_MODEL: '/vault/model-entity/updateModel',
    EXCEL_IMPORT: '/vault/bulkupload/template',
    EXCEL_UPLOAD: '/vault/bulkupload/upload/',
    GET_VERSIONS: '/vault/versions',
    ROLLBACK_VERSION: '/vault/versions_rollback',
    VERSIONS_COMPARISON: '/vault/versions_comparison',
    GET_REPORTS: '/vault/reports',
    GET_CURRENT_STATES: '/vault/model-entity/status',
    GET_MODEL_LINAGE: '/vault/graphplotforsingleentity/getnodes',
    WORKFLOW_CHECKLIST: '/vault/workflow/checklist',
    ALERT_EVENT: '/vault/model-entity/alert_events',
    MODEL_AVAILABLE: '/vault/model-entity/available',
    GET_FIELDS_DATA_TYPES: '/vault/model-entity/getDataType',
    GET_STATE_DETAILS: '/vault/model-entity/StatesDetails',
    GET_SHOW_IN_SUMMARY: '/vault/model-entity/showInSummary',
    GET_TEMPLATE_ASSOCIATIONS: '/vault/model-entity/getLifecycleDetails',
    GET_PARENT_ASSOCIATION: '/vault/model-entity/read/parentAssociation',
    ISSUE_DATA: '/vault/model-entity/read/validationArtifacts',
    SEARCH_MODELS: '/vault/search-notes-entity',
    GET_GLOBAL_ATTRIBUTE_VALUE: '/vault/globalattribute-value',
    GET_LOCAL_ATTRIBUTE_VALUE: '/vault/attribute-value',
    GET_MODEL_PREVIOUS_STATE: '/vault/model-entity/getModelPreviousState',
    RECLAIM_STATE: '/vault/model-entity/modelReclaim',
    DOWNLOAD_LOGS: '/vault/model-entity/modelHistoryDownload',
    GET_USER_ROLE_PERMISSIONS: '/vault/model-entity/getUserPermission',
    GET_MAPPED_VALUES: '/vault/model-entity/getMappedValues',
  },
  DOC: {
    MODEL_DOCUMENTS: '/vault/document',
    UPLOAD_DOCUMENTS: '/vault/upload-document',
    CREATE_DOCUMENT: '/vault/create-document',
    EDIT_DOCUMENTS: '/vault/edit_document',
    UPLOAD_TEMPLATE: '/vault/documenttemplateupload',
    DOCUMENT_TEMPLATE: '/vault/documenttemplate',
    DOCUMENT_ONEDRIVE_URL: '/vault/documentonedriveview',
    DOCUMENT_TREE: '/vault/doc_tree',
    DOWNLOAD_DOCUMENT: '/vault/export-document',
    REPLACE_DOCUMENT: '/vault/update-document',
    GET_TAGS: '/vault/documentTags',
    DOWNLOAD_WEAKNESS_ASSESSMENT: '/vault/llm/doc-assess',
  },
  RR: {
    RULES: '/vault/rules',
  },
  WF: {
    CREATE_WORKFLOW: '/vault/workflow/create',
    CLONE_WORKFLOW: '/vault/workflow/clone',
    GET_WORKFLOW_LIST: '/vault/workflow/viewAll',
    COMPILE_FLOW: '/vault/workflow/recompile',
    UPDATE_FLOW: '/vault/workflow/update',
    GET_SINGLE_WORKFLOW: '/vault/workflow/view',
    GET_ALL_STATES: '/vault/workflow/Allstate',
    NEXT_POSSIBLE_STATE: '/vault/workflow/getNextState',
    DELETE_WORKFLOW: '/vault/workflow/delete',
    CHECK_WORKFLOW_STATE_DELETABLE: '/vault/workflow/checkstate',
  },
  GA: {
    GLOBAL_ATTRIBUTES: '/vault/globalattributes',
  },
  DB: {
    GET_FILTER: '/vault/savesearch',
    GET_FILTERED_DATA: '/vault/search',
    GET_NODES: '/vault/graphplot/getnodes/',
    PLOT_GRAPH: '/vault/graphplot/plotgraph/',
    FULL_SEARCH: '/vault/fullsearch',
    PIPELINE: '/vault/pipeline',
    DASHBOARD_REPORTS: '/vault/dashreports',
    UPDATE_ALERT: '/vault/update_alert_field',
    ALL_EVENTS: '/vault/allevents',
    EVENTS_COUNT: '/vault/get_events_count',
    GET_ALERTS: '/vault/alert',
    GET_KIBAN_DASHBOARD: '/vault/kibanadashboard',
    GET_PENDING_TASKS: '/vault/model-entity/MyTask',
    GET_OPEN_TASKS: '/vault/auth/openTasks',
  },
  DI: {
    GET_INVENTORY: '/vault/docinv',
    DOC_SEARCH: '/vault/documentsearch',
    GET_DOC_MARKDOWN: '/vault/file-content',
    GET_VERSION_DETAILS: '/vault/doc-version',
    ROLL_BACK_VERSION: '/vault/rollback-document',
  },
  BU: {
    GET_FILES: '/vault/bulkupload/files/',
    CREATE_MODEL: '/vault/bulkupload/create-model/',
    DELETE_FILE: '/vault/bulkupload/files/delete',
  },
  UM: {
    CREATE_GROUP: '/vault/auth/creategroup',
    DELETE_GRPUP: '/vault/auth/deletegroup',
    GET_GROUPS: '/vault/auth/readgroup',
    UPDATE_GROUP: '/vault/auth/updategroup',
    GET_GROUP_TASKS: '/vault/auth/getGroupTask',
    ASSIGN_TASK: '/vault/auth/assignTask',
    GET_ROLES: '/vault/roles',
    GET_ISSUE_TYPE: '/vault/custom-entity/issue-type',
    CREATE_ISSUE_TYPE: '/vault/custom-entity/issue-type',
    UPDATE_ISSUE_TYPE: '/vault/custom-entity/issue-type',
    DELETE_ISSUE_TYPE: '/vault/custom-entity/issue-type',
    GET_SUMMARY_SECTIONS: '/vault/model-entity/modelSummarySection',
    CREATE_SUMMARY_SECTIONS: '/vault/model-entity/modelSummarySection',
    UPDATE_SUMMARY_SECTIONS: '/vault/model-entity/modelSummarySection',
    DELETE_SUMMARY_SECTIONS: '/vault/model-entity/modelSummarySection',
  },
  BA: {
    GET_ALL_MODELS: '/vault/bulktransition/allModel',
    GET_STATES: '/vault/bulktransition/getState',
    BULK_TRANSITION: '/vault/bulktransition/transition',
  },
  DC: {
    GET_ALL_REPORT_DETAILS: '/vault/customreports',
    SAVE_CHART: '/vault/customreports',
    DELETE_CHART: '/vault/customreports',
    GET_COLUMNS_NAMES: '/vault/dynamiccharts/columns',
    GET_COLUMNS_METADATA: '/vault/dynamiccharts',
    CHECK_CHART_EXIST: '/vault/checkdynamicchartexists',
  },
  NUI: {
    GET_ALL_MODELS: '/me/mestimation/vault/allmodels',
    GET_ALL_PROJECTS: '/vault/model-entity/getnimbusprojectlist',
    INTEGRATE_ENTITY: '/vault/model-entity/enablemodelintegration',
    GET_ALL_PIPELINES: '/ad/automated_pipeline/pipelinelist',
    RUN_PIPELINE: '/ad/automated_pipeline/runpipeline',
    PIPELINE_STATUS: '/ad/automated_pipeline/pipelinestatus',
  },
  LLM: {
    DOC_CHAT: '/vault/llm/chat',
    ENTITY_CHAT: '/vault/llm/modelchat',
    DOC_AVAILABLE: '/vault/llm/check',
    DOC_UPLOAD: '/vault/llm/document',
    ENTITY_UPLOAD: '/vault/llm/modeltrain',
  },
  AUT: {
    CREATE_AUTOMATION: '/vault/automation',
    UPDATE_AUTOMATION: '/vault/automation',
    GET_AUTOMATION: '/vault/automation',
    DELETE_AUTOMATION: '/vault/automation',
  },
};
export default API_ENDPOINTS;
