import { useDispatch, useSelector } from 'react-redux';
import {
  setModelInventorySearch,
  setModelAssociationSearch,
  setTemplateListSearch,
  setWorkFlowListSearch,
  setRulesSearch,
  setUserListSearch,
  setGroupListSearch,
  setDocumentInventorySearch,
  setDashboardSearch,
  setDashboardActiveFilter,
  setModelInventoryHiddenColumns,
  setModelAssociationHiddenColumns,
  setModelInventoryColumnOrder,
  setModelAssociationColumnOrder,
  setDocumentTemplateSearch,
  setUserSetting,
  setUserRoleListSearch,
  setIssueTypeListSearch,
  setSummaryTabsListSearch,
  setIsWorkflowCompiled,
  setModelRequestSearch,
} from '../store/slices/commonSlicer';

export default function useCommon() {
  const dispatch = useDispatch();
  // @ts-ignore
  const { common } = useSelector((state) => state);
  return {
    dispatch,
    common,
    setModelInventorySearch,
    setModelAssociationSearch,
    setTemplateListSearch,
    setWorkFlowListSearch,
    setRulesSearch,
    setUserListSearch,
    setGroupListSearch,
    setDocumentInventorySearch,
    setDashboardSearch,
    setDashboardActiveFilter,
    setModelInventoryHiddenColumns,
    setModelAssociationHiddenColumns,
    setModelInventoryColumnOrder,
    setModelAssociationColumnOrder,
    setDocumentTemplateSearch,
    setUserSetting,
    setUserRoleListSearch,
    setIssueTypeListSearch,
    setSummaryTabsListSearch,
    setIsWorkflowCompiled,
    setModelRequestSearch,
  };
}
