import { createTheme } from '@mui/material/styles';
import _ from 'lodash';
import { useMemo } from 'react';

import darkTheme from './dark';
import lightTheme from './light';
import configReactQueryBuilderTheme from './configReactQueryBuilderTheme';

/**
 * @description Custom hook to get the current theme based on the theme value.
 * @param {string} themeValue - The current theme value ('light-theme' or 'dark-theme').
 * @returns {object} - The current theme object.
 */
const useCurrentTheme = (themeValue) => {
  return useMemo(
    () => (themeValue === 'light-theme' ? lightTheme : darkTheme),
    [themeValue]
  );
};

/**
 * @description Create a React Query Builder theme based on the selected theme value and configReactQueryBuilderTheme file.
 * @param {string} themeValue - The current theme value ('light-theme' or 'dark-theme').
 * @returns {object} - The React Query Builder theme object.
 */
const ReactQueryBuilderTheme = (themeValue) => {
  const currentTheme = _.cloneDeep(useCurrentTheme(themeValue));
  return createTheme(_.merge(currentTheme, configReactQueryBuilderTheme));
};

export { ReactQueryBuilderTheme };

export default useCurrentTheme;
