const ROLE_PERMISSION_TREE = {
  DI: {
    objKey: 'DI',
    label: 'Details',
    key: 'Details',
    subModules: {
      UA: { objKey: 'UA', label: 'Update Attribute', key: 'UpdateAttribute' },
      SV: { objKey: 'SV', label: 'Save New Version', key: 'SaveNewVersion' },
    },
  },
  DOC: {
    objKey: 'DOC',
    label: 'Documents',
    key: 'Documents',
    subModules: {
      AD: { objKey: 'AD', label: 'Add Document', key: 'AddDocument' },
      GD: {
        objKey: 'GD',
        label: 'Generate Document ',
        key: 'GenerateDocument',
      },
      ED: { objKey: 'ED', label: 'Edit Document', key: 'EditDocument' },
      DD: { objKey: 'DD', label: 'Delete Document', key: 'DeleteDocument' },
    },
  },

  OLE: {
    objKey: 'OLE',
    label: 'Other Lifecycle Events',
    key: 'OtherLifecycleEvents',
    subModules: {
      CA: {
        objKey: 'CA',
        label: 'Create and Add Event',
        key: 'CreateAndAddEvent',
      },
      AE: {
        objKey: 'AE',
        label: 'Add Event ',
        key: 'AddEvent',
      },
      UE: {
        objKey: 'UE',
        label: 'Unlink Lifecycle Event',
        key: 'UnlinkLifecycleEvent',
      },
      DE: { objKey: 'DE', label: 'Delete event', key: 'DeleteLifecycleEvent' },
    },
  },
  TE: {
    objKey: 'TE',
    label: 'Team',
    key: 'Team',
    subModules: {
      AD: { objKey: 'AD', label: 'Add team', key: 'AddTeamMember' },
      DT: { objKey: 'DT', label: 'Delete team', key: 'RemoveTeamMember' },
    },
  },
  CF: {
    objKey: 'CF',
    label: 'Chat Forum',
    key: 'ChatForum',
    subModules: {
      AD: { objKey: 'AD', label: 'Add Note', key: 'AddNote' },
      DN: { objKey: 'DN', label: 'Delete Note', key: 'DeleteNote' },
      UN: { objKey: 'UN', label: 'update Note', key: 'UpdateNote' },
    },
  },
  VE: {
    objKey: 'VE',
    label: 'Versions',
    key: 'Versions',
    subModules: {
      VR: { objKey: 'VR', label: 'Version Rollback', key: 'VersionRollback' },
      VC: { objKey: 'VC', label: 'Version Compare', key: 'VersionCompare' },
    },
  },
  AL: {
    objKey: 'AL',
    label: 'Alerts',
    key: 'Alerts',
    subModules: {
      UA: { objKey: 'UA', label: 'Update Alert', key: 'UpdateAlert' },
    },
  },
};

export default ROLE_PERMISSION_TREE;
