// @ts-nocheck
import { AES, enc, mode } from 'crypto-js';
import { isValueEmpty } from './Utils';

export const encrypt = (text) => {
  if (
    process.env.REACT_APP_ENABLE_ENCRYPTION === 'true' &&
    !isValueEmpty(text)
  ) {
    try {
      let key = process.env.REACT_APP_ENCRYPTION_SECRETE_KEY;
      key = enc.Utf8.parse(key);
      let encrypted = AES.encrypt(text, key, {
        mode: mode.ECB,
      });
      encrypted = encrypted.toString();
      return encrypted;
    } catch (err) {
      throw new Error(err);
    }
  }
};

export const decrypt = (cipherText) => {
  if (
    process.env.REACT_APP_ENABLE_ENCRYPTION === 'true' &&
    !isValueEmpty(cipherText)
  ) {
    let key = process.env.REACT_APP_ENCRYPTION_SECRETE_KEY;
    key = enc.Utf8.parse(key);
    try {
      let decrypted = AES.decrypt(cipherText, key, { mode: mode.ECB });
      decrypted = decrypted.toString(enc.Utf8);
      return decrypted;
    } catch (err) {
      throw new Error(err);
    }
  }
  return cipherText;
};
