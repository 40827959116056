// @ts-nocheck
import { Box, Slide, Typography } from '@mui/material';
import React, { useState } from 'react';
import { ReactMuiTableColumnHeaderTextEllipsis } from 'solytics-frontend';
import PropTypes from 'prop-types';
import { useContextMenu } from 'react-contexify';

import { useTheme } from '@emotion/react';
import { ContextMenus } from '../Common/ContextMenus';
import ReactMuiTableListView from '../Common/ReactMuiTableListView';

const columns = [
  {
    heading: 'USER ID',
    accessor: 'user_id',
    Header: ReactMuiTableColumnHeaderTextEllipsis,
    width: 200,
  },
  {
    heading: 'ACCOUNT ID',
    accessor: 'account_id',
    Header: ReactMuiTableColumnHeaderTextEllipsis,
    width: 200,
  },
  {
    heading: 'USER NAME',
    accessor: 'username',
    Header: ReactMuiTableColumnHeaderTextEllipsis,
    width: 200,
  },
  {
    heading: 'EMAIL',
    accessor: 'email',
    Header: ReactMuiTableColumnHeaderTextEllipsis,
    width: 200,
  },
];
const UserList = ({ userList, tabValue, userMenus, skipPageReset, common }) => {
  const theme = useTheme();
  const { show: showRowLevelMenus } = useContextMenu();
  const [selectedRow, setSelectedRow] = useState(null);
  return (
    <Box>
      {userList?.length > 0 ? (
        <Slide
          key={tabValue}
          direction="left"
          timeout={1000}
          mountOnEnter
          in={true}
        >
          <Box
            display="flex"
            flexGrow={1}
            flexDirection="column"
            overflow="auto"
            height="calc(100vh - 300px)"
          >
            <ReactMuiTableListView
              data={userList?.length > 0 ? userList : []}
              columns={columns}
              getHeaderProps={() => ({
                style: {
                  display: 'flex',
                  alignItems: 'center',
                },
              })}
              getCellProps={(cell) => {
                return {
                  onContextMenu: (e) => {
                    setSelectedRow(cell?.row);
                    showRowLevelMenus({
                      id: `model-inventory-table-menus-user`,
                      event: e,
                      props: cell?.row,
                    });
                  },
                  style: {
                    backgroundColor:
                      cell?.row?.id === selectedRow?.id
                        ? theme.palette.secondary.main
                        : '',
                    position: 'relative',
                  },
                };
              }}
              enableRowSelection={true}
              pageCount={userList?.length}
              enablePagination={true}
              initialPageSize={10}
              initialGlobalFilter={common?.userListSearch}
              rowsPerPageOptions={[5, 10, 15]}
              skipPageReset={skipPageReset}
            />
            <ContextMenus
              menuId="model-inventory-table-menus-user"
              menus={userMenus}
              onMenuExit={() => {
                setSelectedRow(null);
              }}
            />
          </Box>
        </Slide>
      ) : (
        <Box
          display="flex"
          sx={{ height: 'calc(100vh  - 500px)' }}
          flexGrow={1}
          alignItems="center"
          flexDirection="column"
          justifyContent="center"
        >
          <Typography variant="subtitle1">No data result found.</Typography>
        </Box>
      )}
    </Box>
  );
};

UserList.propTypes = {
  userList: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object]))
    .isRequired,
  tabValue: PropTypes.number.isRequired,
  skipPageReset: PropTypes.bool.isRequired,
  common: PropTypes.oneOfType([PropTypes.object]).isRequired,
  userMenus: PropTypes.oneOfType([PropTypes.object]).isRequired,
};

export default UserList;
